import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { imagesUrlArrayGet } from '../../../utils/imagesUrlGenerator';

// _mock_ data
// import SEWINGS from '../../_mocks_/sewings';

export const addSewing = createAsyncThunk(
  'sewings/add',
  async (sewingData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const newSewingRefId = firestore.collection('sewings').doc().id;
    const timestamp = firestore.FieldValue.serverTimestamp();

    const fabricsLen = sewingData.sewingData.fabrics.length;
    const stylesLen = sewingData.sewingData.styles.length;
    const uploadCheck = firebase.functions().httpsCallable('uploadCheck');

    if (fabricsLen > 0 || stylesLen > 0) {
      const uploadFabricCheckBool = await uploadCheck({ quantityToUpload: fabricsLen, type: '3', ownerId: sewingData.ownerId });
      if (!uploadFabricCheckBool.data) {
        throw new Error(
          'You have not enough fabrics upload bundle. Please topup or renew your plan.'
        );
      }

      const uploadStyleCheckBool = await uploadCheck({ quantityToUpload: stylesLen, type: '2', ownerId: sewingData.ownerId });
      if (!uploadStyleCheckBool.data) {
        throw new Error('You have not enough styles upload bundle. Please topup or renew your plan.');
      }
    }

    if (fabricsLen) {
      sewingData.sewingData.fabrics = await imagesUrlArrayGet(
        'fabric',
        firebase,
        newSewingRefId,
        sewingData.sewingData.fabrics
      );
    }

    if (stylesLen) {
      sewingData.sewingData.styles = await imagesUrlArrayGet(
        'style',
        firebase,
        newSewingRefId,
        sewingData.sewingData.styles
      );
    }

    firestore.set(
      { collection: 'sewings', doc: newSewingRefId },
      {
        ...sewingData,
        sewingData: {
          ...sewingData.sewingData,
          createdAt: timestamp,
          updatedAt: timestamp
        }
      }
    );
  }
);

// ----------------------------------------------------------------------------

export const removeSewing = createAsyncThunk(
  'sewings/delete',
  async (sewingData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    firestore.update(
      { collection: 'sewings', doc: sewingData.id },
      { deletedForOwner: firestore.FieldValue.serverTimestamp() }
    );
  }
);

// -----------------------------------------------------------------------------------

export const changeSewingStatus = createAsyncThunk(
  'sewings/statusChange',
  async (sewingData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const sewing = await firestore.update(
      { collection: 'sewings', doc: sewingData.id },
      sewingData
    );

    return sewing;
  }
);

// --------------------------------------------------------------------------------

export const updateSewing = createAsyncThunk(
  'sewings/update',
  async (sewingData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { id } = sewingData;
    const { fabrics, styles } = sewingData.sewingData;
    const timestamp = firestore.FieldValue.serverTimestamp();
    const uploadCheck = firebase.functions().httpsCallable('uploadCheck');

    let fabricFilesToUploadLen = 0;
    fabrics.map(async (file) => {
      if (file instanceof File) {
        fabricFilesToUploadLen += 1;
      }
    });
    if (fabricFilesToUploadLen) {
      const uploadCheckBool = await uploadCheck({
        quantityToUpload: fabricFilesToUploadLen,
        type: '3',
        ownerId: sewingData.ownerId
      });
      if (!uploadCheckBool.data) {
        throw new Error(
          'You have not enough fabrics upload bundle. Please topup or renew your plan.'
        );
      }
    }

    let styleFilesToUploadLen = 0;
    styles.map(async (file) => {
      if (file instanceof File) {
        styleFilesToUploadLen += 1;
      }
    });
    if (styleFilesToUploadLen) {
      const uploadCheckBool = await uploadCheck({
        quantityToUpload: styleFilesToUploadLen,
        type: '2',
        ownerId: sewingData.ownerId
      });
      if (!uploadCheckBool.data) {
        throw new Error(
          'You have not enough styles upload bundle. Please topup or renew your plan.'
        );
      }
    }

    if (fabrics && fabrics.length) {
      sewingData.sewingData.fabrics = await imagesUrlArrayGet('fabric', firebase, id, fabrics);
    }

    if (styles && styles.length) {
      sewingData.sewingData.styles = await imagesUrlArrayGet('style', firebase, id, styles);
    }

    const sewing = await firestore.update(
      { collection: 'sewings', doc: id },
      {
        ...sewingData,
        sewingData: {
          ...sewingData.sewingData,
          updatedAt: timestamp
        }
      }
    );

    return sewing;
  }
);

export const recordMeasurement = createAsyncThunk(
  'sewings/recordMeasurement',
  async (measurementInfo, { extra: { getFirestore } }) => {
    const firestore = getFirestore();
    const refId = firestore.collection('measurementsRecord').doc().id;

    const measurementDoc = await firestore
      .collection('measurementsRecord')
      .where('parentId', '==', measurementInfo.parentId)
      .where('ownerId', '==', measurementInfo.ownerId)
      .get();

    if (!measurementDoc.empty) {
      await firestore.update(
        { collection: 'measurementsRecord', doc: measurementDoc.docs[0].id },
        measurementInfo
      );
    } else {
      await firestore.set(
        { collection: 'measurementsRecord', doc: refId },
        { id: refId, ...measurementInfo},
        { merge: true }
      );
    }


  }
);

// -----------------------------------------------------------------------------

const initialState = {
  error: null
};

const sewingsSlice = createSlice({
  name: 'sewings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSewing.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------

    builder.addCase(removeSewing.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------

    builder.addCase(updateSewing.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // -------------------------------------------------------------------

    builder.addCase(changeSewingStatus.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // -------------------------------------------------------------------

    builder.addCase(recordMeasurement.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default sewingsSlice.reducer;
