import { useFirestoreConnect, populate } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { condenseText } from '../../utils/formatText';

const mergeLists = (ordered, storeAs, limit) => {
  let nextPage = false;
  let list = Object.entries(ordered).filter(([key]) => key.includes(storeAs));
  list = list.map((item, index) => {
    if (item[1].length - 1 === limit) {
      if (list.length - 1 === index) {
        nextPage = true;
      }
      return item[1].slice(0, -1);
    }
    return item[1];
  });
  // return [list.flat(), nextPage];
  return [[...new Map(list.flat().map((v) => [v.customerId, v])).values()], nextPage];
};

const useHook = ({ limit, startAfter, filterName }) => {
  const storeAs = 'customers';
  const key = `${storeAs}/${startAfter || 0}`;
  const populates = [{ child: 'ownerId', root: 'users' }];

  const [query, setQuery] = useState({
    collection: 'customers',
    orderBy: ['customerData.createdAt', 'desc'],
    storeAs: key,
    limit: limit + 1,
    startAfter,
    populates
  });

  useEffect(() => {
    if (filterName) {
      if (Number.isNaN(parseInt(filterName, 10))) {
        const keyword = condenseText(filterName);
        setQuery({
          collection: 'customers',
          where: [
            ['customerData.searchMatch', '>=', keyword],
            ['customerData.searchMatch', '<', `${keyword}z`]
          ],
          storeAs: 'searchedCustomers',
          populates
        });
      } else {
        const parsedValue = filterName.toString();
        setQuery({
          collection: 'customers',
          where: [
            ['customerData.phoneNumber', '>=', parsedValue],
            ['customerData.phoneNumber', '<', `${parsedValue}z`]
          ],
          limit: 20,
          storeAs: 'searchedCustomers',
          populates
        });
      }
    } else {
      setQuery({
        collection: 'customers',
        orderBy: ['customerData.createdAt', 'desc'],
        storeAs: key,
        limit: limit + 1,
        startAfter,
        populates
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName, key, limit, startAfter]);

  useFirestoreConnect(query);

  return useSelector(({ firestore }) => {
    const collection = filterName ? 'searchedCustomers' : key;

    populate(firestore, collection, populates);

    const loading =
      firestore.status.requesting[key] === undefined ||
      Object.entries(firestore.status.requesting).some(
        ([key, value]) => key.includes(storeAs) && value
      );
    const error = Object.entries(firestore.errors).find(
      ([key, value]) => key.includes(storeAs) && !!value
    );
    const [list, nextPage] = mergeLists(firestore.ordered, storeAs, limit);
    return {
      loading,
      error,
      data: list,
      nextPage
    };
  });
};

export default useHook;
