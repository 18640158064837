import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import sewingMachine from '@iconify/icons-si-glyph/sewing-machine';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

// const TOTAL = 1352831;

export default function AppSewingsTotal() {
  const { sewingsCount } = useSelector((state) => state.firebase.profile);

  return (
    <RouterLink style={{ textDecoration: 'none' }} to="/dashboard/sewings">
      <RootStyle>
        <IconWrapperStyle>
          <Icon icon={sewingMachine} width={24} height={24} />
        </IconWrapperStyle>
        <Typography variant="h3">{fShortenNumber(sewingsCount)}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          Total Sewings
        </Typography>
      </RootStyle>
    </RouterLink>
  );
}
