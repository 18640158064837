import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
// import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { format } from 'date-fns';

// --------------------------------------------------

ExpendituresViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  expenditure: PropTypes.object,
  setIsOpen: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func
};

// --------------------------------------------------

export default function ExpendituresViewDialog({
  expenditure,
  isOpen,
  setIsOpen,
  handleEdit,
  handleDelete
}) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const handleDeleteClick = () => {
    handleClose();
    handleDelete();
  };

  const handleEditClick = () => {
    handleClose();
    handleEdit();
  };

  // --------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // --------------------------------------------------

  const { category, amount, createdAt } = expenditure;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Expenditure
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Category
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {category || 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Amount
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  ₵{amount || 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Date
                </Typography>
                <Typography variant="subtitle2">
                  {format(createdAt.toDate(), 'dd MMM yyyy')}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {format(createdAt.toDate(), 'p')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClick} color="error">
            Delete
          </Button>
          <Button onClick={handleEditClick} autoFocus>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
