import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Container,
  Grid,
  Box,
  Alert,
  TextField,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import closeFill from '@iconify/icons-eva/close-fill';
import { useFormik, Form, FormikProvider } from 'formik';
import Toaster from '../../Toaster';

import { getErrorMessage } from '../../../utils/firebaseError';
import { useOwners } from '../../../hooks/super_admin';
import { sendSMS } from '../../../redux/slices/owner/communication';

import { fPhoneNumber } from '../../../utils/formatPhoneNumber';

const SMS_LIMIT = 280;

// ----------------------------------------------------------------------

SendSMSDialog.propTypes = {
  children: PropTypes.node
};

// ----------------------------------------------------------------------

const ChildrenButtonStyle = styled(Button)(() => ({
  width: '100%',
  padding: 0
}));

// ----------------------------------------------------------------------

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function SendSMSDialog({ children }) {
  const authUserProfile = useSelector((state) => state.firebase.profile);
  const { searchedOwners } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);

  // ----------------------------------------------------------------------------------

  const [open, setOpen] = React.useState(false);
  const [initialValues] = React.useState({
    owners: [],
    message: ''
  });
  const [options, setOptions] = React.useState([]);
  const [ownersValue, setOwnersValue] = React.useState([]);
  const [filterName, setFilterName] = React.useState('');
  const [error, setError] = React.useState(null);

  // ----------------------------------------------------------------------

  const dispatch = useDispatch();
  const toastRef = React.useRef();

  // ----------------------------------------------------------------------

  const OwnerSchema = Yup.object().shape({
    owners: Yup.array()
      .min(1, 'Please select at least one owner')
      .required('Please select at least one owner'),
    message: Yup.string()
      .max(SMS_LIMIT, `You cannot exceed ${SMS_LIMIT} characters`)
      .required('Please enter a message')
  });

  const formik = useFormik({
    initialValues,
    // enableReinitialize: true,
    validationSchema: OwnerSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const messageInfo = {
          destination: values.owners.map(({ phoneNumber }) => fPhoneNumber(phoneNumber)).join(','),
          senderId: authUserProfile?.senderId,
          message: values.message
        };

        await dispatch(sendSMS(messageInfo));
        toastRef.current.handleOpen('SMS Sent');
        // reset form
        resetForm();
        setOwnersValue([]);
        setOptions([]);
        // clsoe dialog
        setOpen(false);
      } catch (error) {
        setError(getErrorMessage(error) || error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // ----------------------------------------------------------------------

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ----------------------------------------------------------------------

  useOwners({ filterName, limit: 15 });

  // ----------------------------------------------------------------------

  React.useEffect(() => {
    let active = true;

    if (filterName === '') {
      setOptions(ownersValue || []);
      return undefined;
    }

    if (active) {
      setOptions(searchedOwners || []);
      setError(null);
    }

    return () => {
      active = false;
    };
  }, [filterName, ownersValue, searchedOwners]);

  // ----------------------------------------------------------------------

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  return (
    <div>
      <ChildrenButtonStyle onClick={handleClickOpen}>{children}</ChildrenButtonStyle>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <Icon icon={closeFill} width={24} height={24} />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Send SMS
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm" sx={{ pb: 5 }}>
          <Box sx={{ pb: 3, pt: 4 }}>
            <Typography variant="h4">Send New SMS</Typography>
          </Box>
          {error && (
            <Alert sx={{ mt: -1.5, mb: 3 }} severity="error">
              {error.message || error}
            </Alert>
          )}
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    getOptionLabel={({ companyName }) => companyName || ''}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterOptions={(x) => x}
                    options={[...ownersValue, ...options]}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={ownersValue}
                    onChange={(event, newValue) => {
                      const newOwner = newValue[newValue.length - 1];
                      if (newOwner?.phoneNumber === '') {
                        const ownerName = `${newOwner.firstName || ''} ${newOwner.lastName || ''}`;
                        setError(`${ownerName} has no phone number`);
                        return;
                      }
                      setOptions(newValue ? [...newValue, ...options] : options);
                      setOwnersValue(newValue);
                      formik.setFieldValue('owners', newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setFilterName(newInputValue);
                    }}
                    // loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Owner(s)"
                        error={Boolean(errors.owners)}
                        helperText={errors.owners}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {requesting.searchedOwners ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    multiline
                    rows={6}
                    {...getFieldProps('message')}
                    error={Boolean(touched.message && errors.message)}
                    helperText={
                      values.message.length
                        ? `${values.message.length}/${SMS_LIMIT}`
                        : errors.message
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Send
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Container>
      </Dialog>

      <Toaster ref={toastRef} />
    </div>
  );
}
