import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const addMeasurementsParent = createAsyncThunk(
  'measurementsParent/add',
  async (measurementData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const parentDocRefId = firestore.collection('measurementsParent').doc().id;
    const parentDocRef = firestore.collection('measurementsParent').doc(parentDocRefId);
    const timestamp = firestore.FieldValue.serverTimestamp();

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      const newParentCount = superAdminDoc.data().measurementsParentCount + 1;
      transaction.set(parentDocRef, {
        ...measurementData,
        isActive: true,
        createdAt: timestamp,
        updatedAt: timestamp
      });

      transaction.update(superAdminDocRef, { measurementsParentCount: newParentCount });
    });
  }
);

// -----------------------------------------------------------------------------------------

export const updateMeasurementsParent = createAsyncThunk(
  'measurementsParent/update',
  async (measurementData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();
    const timestamp = firestore.FieldValue.serverTimestamp();
    const batch = firestore.batch();

    const measurementsListWithParent = firestore
      .collection('measurementsList')
      .where('parentId', '==', measurementData.id);

    const measurementsList = await measurementsListWithParent.get();

    measurementsList.forEach((doc) => {
      batch.update(doc.ref, {
        isActive: measurementData.isActive,
        updatedAt: timestamp
      });
    });

    firestore.update(
      { collection: 'measurementsParent', doc: measurementData.id },
      {
        ...measurementData,
        updatedAt: timestamp
      }
    );

    await batch.commit();
  }
);

// -----------------------------------------------------------------------------------------

export const removeMeasurementsParent = createAsyncThunk(
  'measurementsParent/delete',
  async (measurementData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const batch = firestore.batch();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const parentDocRef = firestore.collection('measurementsParent').doc(measurementData.id);
    const measurementsListWithParent = firestore
      .collection('measurementsList')
      .where('parentId', '==', measurementData.id);

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);
      const parentDoc = await transaction.get(parentDocRef);
      const measurementsListWithParentQuery = await measurementsListWithParent.get();

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      if (!parentDoc.exists) {
        throw new Error('Measurement parent does not exist!');
      }

      const newParentCount = superAdminDoc.data().measurementsParentCount - 1;
      let listCount = superAdminDoc.data().measurementsListCount;

      transaction.delete(parentDocRef);
      measurementsListWithParentQuery.docs.forEach((doc) => {
        batch.delete(doc.ref);
        listCount -= 1;
      });
      transaction.update(superAdminDocRef, {
        measurementsParentCount: newParentCount,
        measurementsListCount: listCount
      });

      await batch.commit();
    });
  }
);

// -----------------------------------------------------------------------------------------

// ===================================== LIST ==============================================

// -----------------------------------------------------------------------------------------

export const addMeasurementsList = createAsyncThunk(
  'measurementsList/add',
  async (measurementData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const listDocRefId = firestore.collection('measurementsList').doc().id;
    const listDocRef = firestore.collection('measurementsList').doc(listDocRefId);
    const timestamp = firestore.FieldValue.serverTimestamp();

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      const newListCount = superAdminDoc.data().measurementsListCount + 1;
      transaction.set(listDocRef, {
        ...measurementData,
        isActive: true,
        createdAt: timestamp,
        updatedAt: timestamp
      });

      transaction.update(superAdminDocRef, { measurementsListCount: newListCount });
    });
  }
);

// -----------------------------------------------------------------------------------------

export const updateMeasurementsList = createAsyncThunk(
  'measurementsList/update',
  async (measurementData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const timestamp = firestore.FieldValue.serverTimestamp();

    firestore.update(
      { collection: 'measurementsList', doc: measurementData.id },
      {
        ...measurementData,
        updatedAt: timestamp
      }
    );
  }
);

// -----------------------------------------------------------------------------------------

export const removeMeasurementsList = createAsyncThunk(
  'measurementsList/delete',
  async (measurementData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const superAdminId = firebase.auth().currentUser.uid;
    const superAdminDocRef = firestore.collection('users').doc(superAdminId);
    const listDocRef = firestore.collection('measurementsList').doc(measurementData.id);

    await firestore.runTransaction(async (transaction) => {
      const superAdminDoc = await transaction.get(superAdminDocRef);
      const listDoc = await transaction.get(listDocRef);

      if (!superAdminDoc.exists) {
        throw new Error('Super Admin does not exist!');
      }

      if (!listDoc.exists) {
        throw new Error('Measurement list item does not exist!');
      }

      const newListCount = superAdminDoc.data().measurementsListCount - 1;
      transaction.delete(listDocRef);

      transaction.update(superAdminDocRef, { measurementsListCount: newListCount });
    });
  }
);

const initialState = {
  error: null
};

const measurementsSlice = createSlice({
  name: 'measurements',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addMeasurementsParent.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addMeasurementsParent.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------------------

    builder.addCase(removeMeasurementsParent.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(removeMeasurementsParent.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------------------

    builder.addCase(updateMeasurementsParent.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(updateMeasurementsParent.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------------------

    builder.addCase(addMeasurementsList.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addMeasurementsList.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ----------------------------------------------------------------------------------

    builder.addCase(updateMeasurementsList.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(updateMeasurementsList.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default measurementsSlice.reducer;
