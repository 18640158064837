import * as Yup from 'yup';
import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { isLoaded } from 'react-redux-firebase';

import { verification } from '../../../redux/slices/auth';

import { getErrorMessage } from '../../../utils/firebaseError';

// ----------------------------------------------------------------------

VerificationForm.propTypes = {
  firebase: PropTypes.object
};

// ----------------------------------------------------------------------

function VerificationForm() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // ----------------------------------------------------------------------

  const _isMounted = useRef(true);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.firebase.profile);

  // ----------------------------------------------------------------------

  const RegisterSchema = Yup.object().shape({
    code: Yup.string().required('Verification code required')
  });

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await dispatch(verification(values));
        resetForm();
        if (isLoaded(profile)) navigate('/dashboard', { replace: true });
      } catch (error) {
        // handles the lost of confirmation attached to the window object
        if (error.name === 'TypeError') setError('Something went wrong');
        else setError(getErrorMessage(error) || error.message);
        window.scrollTo(0, 0);
      } finally {
        if (_isMounted.current) {
          setSubmitting(false);
        }
      }
    }
  });

  // ----------------------------------------------------------------------

  useEffect(
    () => () => {
      _isMounted.current = false;
    },
    []
  );

  // ----------------------------------------------------------------------

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      {error && (
        <Alert sx={{ mt: -1, mb: 3 }} severity="error">
          {error}
        </Alert>
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 3 }}>
            <TextField
              fullWidth
              label="Verification Code"
              {...getFieldProps('code')}
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />
          </Stack>

          <LoadingButton
            id="sign-up-button"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Verify
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default VerificationForm;
