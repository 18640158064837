import { useState, useEffect } from 'react';
import { isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import "./Inventory.css";

// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress
} from '@mui/material';
import {
  StockListToolbar,
  StockEmpty,
  StockListHead,
  StockViewDialog,
  StockStatusDialog,
  StockDeleteDialog
  // StockPwdResetDialog
} from '../../components/_dashboard/owner/stock';
import { AddStockDialog, AddCategoryDialog} from '../../components/_adds/owner';
import { checkForRole } from '../../utils/checkAuthUserRole';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';

import { useStocks } from '../../hooks/owner';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'item', label: 'Item', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'cost_price', label: 'Cost price', alignRight: false },
  { id: 'sales_price', label: 'Sales price', alignRight: false },
  { id: 'store_quantity', label: 'Store Quantity', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function Stock() {
  const { searchedStocks } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { ownersCount } = useSelector((state) => state.firebase.profile);

  // ------------------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [cursor, setCursor] = useState(undefined);
  const [selectedStock, setSelectedStock] = useState(null);
  const [isStockEditOpen, setIsStockEditOpen] = useState(false);
  const [isCategoryEditOpen, setIsCategoryEditOpen] = useState(false);
  const [isStockStatusOpen, setIsStockStatusOpen] = useState(false);
  // const [isStockPwdResetOpen, setIsStockPwdResetOpen] = useState(false);
  const [stockOpen, setStockOpen] = useState(false);
  const [isStockDeleteOpen, setIsStockDeleteOpen] = useState(false);

  const [isAddStockOpen, setIsAddStockOpen] = useState(false);

  const stocks = useStocks({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  const { token, ownerId } = useSelector((state) => state.firebase.profile);
  const currUserId = useSelector((state) => state.firebase.auth.uid);

  const { identifier } = token && checkForRole(token);

  const uid = identifier === 'staff' ? ownerId : currUserId;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(stocks.data[stocks.data.length - 1].createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleStockClick = (stock) => {
    setSelectedStock(stock);
    setStockOpen(true);
  };

  const handleDelete = (item) => {
    setIsStockDeleteOpen(true);
    setSelectedStock(item);
  };

  const handleEdit = (item) => {
    setIsStockEditOpen(true);
    setSelectedStock(item);
  };
  // ------------------------------------------------------------------------

  useEffect(() => {
    if (
      !isStockEditOpen &&
      !isStockDeleteOpen &&
      !stockOpen &&
      !isStockStatusOpen &&
      // !isStockPwdResetOpen &&
      !isAddStockOpen
    ) {
      setSelectedStock(null);
    }
  }, [
    isStockEditOpen,
    isStockDeleteOpen,
    stockOpen,
    isAddStockOpen,
    isStockStatusOpen
    // isStockPwdResetOpen
  ]);

  useEffect(() => {
    if (requesting.searchedStocks) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedStocks]);

  // --------------------------------------------------------------------------------

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName ? searchedStocks?.length : stocks.data?.length
        )
      : 0;

  return (
    <Page title="Owners | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}  className="stack_container">
          <Typography variant="h4" gutterBottom>
            Stocks
          </Typography>
          <AddStockDialog
            isOwnerOpen={isStockEditOpen}
            setIsOwnerOpen={setIsStockEditOpen}
            selectedStock={selectedStock}
          >
              <Button maxWidth="sm" sx={{position: 'relative'}} variant="contained" component = "div" startIcon={<Icon icon={plusFill} />}>
                Add Stock
              </Button>
          </AddStockDialog>
          <AddCategoryDialog
            isOwnerOpen={isCategoryEditOpen}
          >
          <Button
            maxWidth="sm"
            sx={{position: 'relative', right: '15%'}}
            color="info"
            variant="contained"
            startIcon={<Icon icon={plusFill}
            onClick={isCategoryEditOpen}
          />}>
              Add Category
          </Button>
          </AddCategoryDialog>
        </Stack>
        <Card>
          {!!stocks.data.length && (
            <StockListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedStocks}
            />
          )}

          {stocks.loading || requesting.searchedStocks ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!stocks.data.length ? (
                <StockEmpty />
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <StockListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={filterName ? searchedStocks?.length || 0 : ownersCount || 0}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName ? searchedStocks || stocks.data : stocks.data)
                          .filter(item => item.ownerId === uid)
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { itemName, category, costPrice, salesPrice, storeQuantity } =
                              row;

                            return (
                              <TableRow
                                hover
                                key={row.id}
                                sx={{ cursor: 'pointer' }}
                                tabIndex={-1}
                                onClick={() => handleStockClick(row)}
                              >
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{itemName}</TableCell>
                                <TableCell align="left">{category}</TableCell>
                                <TableCell align="left">{costPrice || 'N/A'}</TableCell>
                                <TableCell align="left">{salesPrice || 'N/A'}</TableCell>
                                <TableCell align="left">{storeQuantity}</TableCell>
                                <TableCell align="right">
                                  <TableCell align="right">
                                    {/* <Button
                                      variant="contained"
                                      component="div"
                                      startIcon={<Icon icon={eyeOutlined} />}
                                      onClick={() => handleStockClick(row)}
                                      sx={{ mr: 1.5 }}
                                    >
                                      View
                                    </Button> */}
                                    {/* <Button
                                      variant="contained"
                                      component="div"
                                      startIcon={<Icon icon={plusFill} />}
                                      onClick={(e) => handleAddStock(e, row)}
                                    >
                                      Add Stock
                                    </Button> */}
                                  </TableCell>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedStocks) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedStocks && !!stocks.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filterName ? searchedStocks?.length || 0 : ownersCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {/* ----- Dialogs start ----- */}
        {stockOpen && (
          <StockViewDialog
            isOpen={stockOpen}
            setIsOpen={setStockOpen}
            stock={selectedStock}
            handleEdit={() => handleEdit(selectedStock)}
            handleDelete={() => handleDelete(selectedStock)}
          />
        )}
        {isStockStatusOpen && (
          <StockStatusDialog
            isOpen={isStockStatusOpen}
            setIsOpen={setIsStockStatusOpen}
            selectedStock={selectedStock}
          />
        )}
        {isStockDeleteOpen && (
          <StockDeleteDialog
            isOpen={isStockDeleteOpen}
            setIsOpen={setIsStockDeleteOpen}
            selectedStock={selectedStock}
          />
        )}
      </Container>
    </Page>
  );
}
