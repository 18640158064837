import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
// material
import { Stack, Typography } from '@mui/material';
//

SubscriptionsCurrentBundle.propTypes = {
  bundle: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default function SubscriptionsCurrentBundle({ bundle }) {
  const { subscriptionsCategories } = useSelector((state) => state.firestore.ordered);

  const getCategory = (identifier) => {
    const category = subscriptionsCategories.find((category) => category.identifier === identifier);
    return category?.name;
  };

  return (
    <Stack spacing={3} alignItems="flex-end">
      <Stack flexDirection="row" justifyContent="space-between" sx={{ width: 1 }}>
        <Typography variant="subtitle2">Bundle</Typography>
        <Typography variant="subtitle2">Balance/Expiry</Typography>
      </Stack>

      {bundle.length > 0 ? (bundle.map(({ category, quantity, duration }) => (
        <Stack key={category} spacing={2} sx={{ width: 1 }}>
          <Stack direction="row" justifyContent="space-between" sx={{ width: 1 }}>
            <Typography variant="body2" sx={{ minWidth: 160 }}>
              {getCategory(category)}
            </Typography>
            {quantity || quantity === 0 ? (
              <Typography variant="body2">{quantity}</Typography>
            ) : null}
            {duration ? (
              <Typography variant="body2">
                {new Date(duration) < new Date() ? 'Expired' : format(duration, 'dd MMM yyyy')}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
      ))):null}
    </Stack>
  );
}
