import React from 'react';
import Proptypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

// function TransitionLeft(props) {
//   return <Slide {...props} direction="left" />;
// }

// function TransitionUp(props) {
//   return <Slide {...props} direction="up" />;
// }

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

// function TransitionDown(props) {
//   return <Slide {...props} direction="down" />;
// }

const Toaster = React.forwardRef(
  (
    {
      variant = 'success',
      autoHideDuration = 5000,
      anchorOrigin = { vertical: 'top', horizontal: 'right' },
      key,
      TransitionComponent = TransitionRight,
      TransitionProps = {}
    },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');

    React.useImperativeHandle(ref, () => ({
      handleOpen(message) {
        setMessage(message);
        setOpen(true);
      }
    }));

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') return;
      setOpen(false);
    };
    
    return (
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={autoHideDuration}
        anchorOrigin={anchorOrigin}
        TransitionComponent={TransitionComponent}
        key={TransitionComponent ? TransitionComponent.name : key}
        TransitionProps={TransitionProps}
      >
        <Alert onClose={handleClose} severity={variant} sx={{ width: '100%', color: 'text.white' }}>
          {message}
        </Alert>
      </Snackbar>
    );
  }
);

Toaster.propTypes = {
  variant: Proptypes.string,
  autoHideDuration: Proptypes.number,
  anchorOrigin: Proptypes.object,
  key: Proptypes.string,
  TransitionComponent: Proptypes.func,
  TransitionProps: Proptypes.object
};

export default Toaster;
