import * as React from 'react';
import { Icon } from '@iconify/react';
import shoppingFilled from '@iconify/icons-ant-design/shopping-filled';
import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';

export default function ExpendituresEmpty() {
  const RootStyle = styled(Paper)(() => ({
    height: '60vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }));

  const IconStyle = styled(Icon)(({ theme }) => ({
    color: theme.palette.grey[400]
  }));

  const TypographyStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[500]
  }));

  return (
    <RootStyle>
      <IconStyle icon={shoppingFilled} width={95} height={95} />
      <TypographyStyle variant="subtitle1" sx={{ mt: 1 }}>
        No Expenditures yet
      </TypographyStyle>
    </RootStyle>
  );
}
