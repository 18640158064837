import merge from 'lodash/merge';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, TextField } from '@mui/material';
// components
import { BaseOptionChart } from '../../../chart';

// ----------------------------------------------------------------------

// const CHART_DATA = [
//   {
//     year: 2020,
//     data: [
//       { name: 'Invoice', data: [10, 41, 35, 151, 49, 62, 69, 91, 48, 62, 49, 32] },
//       { name: 'Income', data: [19, 61, 85, 121, 79, 42, 89, 11, 38, 82, 99, 44] },
//       { name: 'Expenses', data: [10, 34, 13, 56, 77, 88, 99, 77, 45, 55, 34, 78] }
//     ]
//   }
// ];

const categories = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export default function BankingBalanceStatistics() {
  const currentYear = new Date().getFullYear();
  const ownerId = useSelector((state) => state.firebase.auth.uid);
  const { finances } = useSelector((state) => state.firestore.ordered);
  const owner = useSelector((state) => state.firebase.profile);

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [years, setYears] = useState([]);
  const [query, setQuery] = useState({
    collection: 'finances',
    where: [
      ['ownerId', '==', ownerId],
      ['year', '==', selectedYear]
    ]
  });
  const [chartData, setChartData] = useState([]);

  // ----------------------------------------------------------------------

  const handleChangeSelectedYear = (event) => {
    setSelectedYear(Number(event.target.value));
    setQuery({
      collection: 'finances',
      where: [
        ['ownerId', '==', ownerId],
        ['year', '==', selectedYear]
      ]
    });
  };

  const chartOptions = merge(BaseOptionChart(), {
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories
    },
    tooltip: {
      y: {
        formatter: (val) => `₵${val}`
      }
    }
  });

  // ---------------------------------------------------------------------

  useEffect(() => {
    const startYear = owner.createdAt.toDate().getFullYear();
    const oneYearFromNow = new Date().getFullYear();
    const yearsArray = Array.from(
      new Array(oneYearFromNow - startYear + 1),
      (val, index) => index + startYear
    );
    setYears(yearsArray);
  }, [owner.createdAt]);

  useEffect(() => {
    const data = [
      { name: 'Invoice', data: [] },
      { name: 'Income', data: [] },
      { name: 'Expenditure', data: [] }
    ];

    categories.forEach((category) => {
      if (finances && finances.length > 0) {
        const currFinance = finances[0].data[category];
        if (currFinance) {
          data[0].data.push(currFinance.invoice);
          data[1].data.push(currFinance.income);
          data[2].data.push(currFinance.expenditure);
        } else {
          data[0].data.push(null);
          data[1].data.push(null);
          data[2].data.push(null);
        }
      } else {
        data[0].data.push(null);
        data[1].data.push(null);
        data[2].data.push(null);
      }
    });

    setChartData(data);
  }, [finances, query]);

  useEffect(() => {
    setQuery({
      collection: 'finances',
      where: [
        ['ownerId', '==', ownerId],
        ['year', '==', currentYear]
      ]
    });
  }, [currentYear, ownerId]);

  // ---------------------------------------------------------------------

  useFirestoreConnect(query);

  return (
    <Card>
      <CardHeader
        title="Balance Statistics"
        // subheader="(+43% Income | +12% Expense) than last year"
        action={
          <TextField
            select
            fullWidth
            value={selectedYear}
            SelectProps={{ native: true }}
            onChange={handleChangeSelectedYear}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
              '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
              '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
            }}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </TextField>
        }
      />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
