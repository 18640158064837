import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
// form
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import { Stack, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { resetPassword } from '../../../redux/slices/auth';
import { getErrorMessage } from '../../../utils/firebaseError';

// ----------------------------------------------------------------------

const ResetPasswordForm = ({ firebase }) => {
  const navigate = useNavigate();
  const _isMounted = useRef(true);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const ResetPasswordSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .max(9, 'Invalid phone number')
      .min(9, 'Invalid phone number')
      .required('Phone number is required')
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: ''
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await dispatch(resetPassword(values));

        sessionStorage.setItem('phone-recovery', values.phoneNumber);

        navigate('/new-password');
      } catch (error) {
        setError(getErrorMessage(error) || error.message);
        window.scrollTo(0, 0);
      } finally {
        if (_isMounted.current) {
          setSubmitting(false);
        }
      }
    }
  });

  useEffect(() => {
    firebase.auth().useDeviceLanguage();

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('request-button', {
      size: 'invisible'
    });
    return () => {
      window.recaptchaVerifier.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      _isMounted.current = false;
    },
    []
  );

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      {error && (
        <Alert sx={{ mt: -2, mb: 3 }} severity="error">
          {error}
        </Alert>
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            label="Phone number"
            type="number"
            {...getFieldProps('phoneNumber')}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
          />

          <LoadingButton
            id="request-button"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Send Request
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

ResetPasswordForm.propTypes = {
  firebase: PropTypes.object
};

export default withFirebase(ResetPasswordForm);
