import merge from 'lodash/merge';
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
// @mui
import { Card, CardHeader, Box, TextField } from '@mui/material';
// components
import { BaseOptionChart } from '../../../chart';

// ----------------------------------------------------------------------

// const CHART_DATA = [
//   {
//     year: 2019,
//     data: [
//       { name: 'Owners', data: [10, 41, 35, 51, 49, 62, 69, 91, 148] },
//       { name: 'Customers', data: [10, 34, 13, 56, 77, 88, 99, 77, 45] },
//       { name: 'Sewings', data: [15, 44, 23, 46, 97, 28, 49, 57, 65] }
//     ]
//   },
//   {
//     year: 2020,
//     data: [
//       { name: 'Owners', data: [148, 91, 69, 62, 49, 51, 35, 41, 10, 40, null, null] },
//       { name: 'Customers', data: [45, 77, 99, 88, 77, 56, 13, 34, 10, 20, 50, 90] },
//       { name: 'Sewings', data: [55, 97, 49, 38, 87, 26, 53, 74, 40, 20, 70, 20] }
//     ]
//   }
// ];

const categories = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export default function AppYearlySummary() {
  const currentYear = new Date().getFullYear();
  const superAdmin = useSelector((state) => state.firebase.profile);
  const { metrics } = useSelector((state) => state.firestore.ordered);
  const superAdminId = superAdmin.token.claims.user_id;

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [years, setYears] = useState([]);
  const [query, setQuery] = useState({
    collection: 'metrics',
    where: [
      ['ownerId', '==', superAdminId],
      ['year', '==', selectedYear]
    ]
  });
  const [chartData, setChartData] = useState([]);

  // ----------------------------------------------------------------------

  const handleChangeSelectedYear = (event) => {
    setSelectedYear(Number(event.target.value));
    setQuery({
      collection: 'metrics',
      where: [
        ['ownerId', '==', superAdminId],
        ['year', '==', selectedYear]
      ]
    });
  };

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories
    }
  });

  // ---------------------------------------------------------------------

  useEffect(() => {
    const startYear = superAdmin.createdAt.toDate().getFullYear();
    const oneYearFromNow = new Date().getFullYear();
    const yearsArray = Array.from(
      new Array(oneYearFromNow - startYear + 1),
      (val, index) => index + startYear
    );
    setYears(yearsArray);
  }, [superAdmin.createdAt]);

  useEffect(() => {
    const data = [
      { name: 'Owners', data: [] },
      { name: 'Customers', data: [] },
      { name: 'Sewings', data: [] }
    ];

    categories.forEach((category) => {
      if (metrics && metrics.length > 0) {
        const currMetric = metrics[0].data[category];
        if (currMetric) {
          data[0].data.push(currMetric.ownersCount);
          data[1].data.push(currMetric.customersCount);
          data[2].data.push(currMetric.sewingsCount);
        } else {
          data[0].data.push(null);
          data[1].data.push(null);
          data[2].data.push(null);
        }
      } else {
        data[0].data.push(null);
        data[1].data.push(null);
        data[2].data.push(null);
      }
    });

    setChartData(data);
  }, [metrics, query]);

  useEffect(() => {
    setQuery({
      collection: 'metrics',
      where: [
        ['ownerId', '==', superAdminId],
        ['year', '==', selectedYear]
      ]
    });
  }, [selectedYear, superAdminId]);

  // ---------------------------------------------------------------------

  useFirestoreConnect(query);

  return (
    <Card>
      <CardHeader
        title="Yearly Summary"
        subheader="(+43%) than last year"
        action={
          <TextField
            select
            fullWidth
            value={selectedYear}
            SelectProps={{ native: true }}
            onChange={handleChangeSelectedYear}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': {
                pl: 1,
                py: 0.5,
                pr: '24px !important',
                typography: 'subtitle2'
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: 0.75,
                bgcolor: 'background.neutral'
              },
              '& .MuiNativeSelect-icon': {
                top: 4,
                right: 0,
                width: 20,
                height: 20
              }
            }}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </TextField>
        }
      />

      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
