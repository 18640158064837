// form
import { useState, useEffect } from 'react';
import { useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
// @mui
import { Box, Stack, Button, Divider, Grid, Typography, InputAdornment, Autocomplete, TextField } from '@mui/material';
// utils
import { fNumber } from '../../../../utils/formatNumber';
// components
import Iconify from '../../../../components/Iconify';
import { RHFTextField } from '../../../../components/hook-form';
import { useStocks } from '../../../../hooks/owner';

// ----------------------------------------------------------------------
InvoiceNewEditDetails.propTypes = {
  selectedShopName: PropTypes.string,
};

export default function InvoiceNewEditDetails({selectedShopName}) {
  const { control, setValue, watch } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const [taxes, setTaxes] = useState(0);
  const [discounts, setDiscounts] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [storeQuantity, setStoreQuantity] = useState(0);

  const currentUser = useSelector((state) => state.firebase.profile);
  const ownerId = useSelector((state) => state.firebase.auth).uid;
  const productList = useStocks().data;
  const Items = selectedShopName==='All'?(productList.map(item => item.itemName))
  :(productList.map(item => item.itemName));

  const values = watch();

  const handleList = (itemName, index) => {
    if (itemName === undefined || itemName === null) return;
    const arr = productList.find(element=>element.itemName === itemName);
    // setStoreQuantity(arr.storeQuantity + watch().items[index].quantity);
    setValue(`items[${index}].title`, arr.category);
    setValue(`items[${index}].description`, arr.description);
    setValue(`items[${index}].price`, arr.salesPrice);
    setValue(`items[${index}].tax`, arr.tax);
    setValue(`items[${index}].costPrice`, arr.costPrice);
    setValue(`items[${index}].shopName`, arr.shopName)
    setValue(`items[${index}].service`, itemName);
    setValue(`items[${index}].quantity`, arr.storeQuantity);
    setValue(`items[${index}].discount`, 0);
    setValue(`items[${index}].id`, arr.id);
    calcTaxes();
    calcSubtotal();
  }

  const handleAdd = () => {
    append({
      title: '',
      description: '',
      service: '',
      quantity: '',
      price: '',
      costPrice: '',
      total: '',
      profit: '',
      discount: '',
      shopName: '',
      id: '',
    });
  };

  const handleRemove = (index) => {
    remove(index);
  };

  const calcTaxes = () => {
    const ivalues = watch().items;
    const total=(ivalues.reduce((total,currentItem) =>  total += currentItem.quantity * currentItem.tax , 0 ));
    // setValue('taxes', total);
    setTaxes(total || 0);
    calcSubtotal();
    // if (!total.isNaN) setValue('taxes', total);    // setTaxes(sumTaxes);
  }

  const calcDiscounts = () => {
    const ivalues = watch().items;
    const total=(ivalues.reduce((total,currentItem) =>  total += currentItem.discount , 0 ));
    // setValue('discounts', total);
    setDiscounts(total || 0);
    calcSubtotal();
    // if (!total.isNaN) setValue('taxes', total);    // setTaxes(sumTaxes);
  }
  // console.log(values.items);

  const calcSubtotal = () => {
    const ivalues = watch().items;
    const total=(ivalues.reduce((total,currentItem) =>  total += currentItem.price * currentItem.quantity , 0 ));
    setSubtotal(total);
  }

  useEffect(() => {
    calcTaxes();
    calcDiscounts();
    calcSubtotal();
  });

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        Details:
      </Typography>

      <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
        {fields.map((item, index) => (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
            <Autocomplete
              fullWidth
              size="small"
              disablePortal
              // id="combo-box-demo"
              options={Items}
              defaultValue={item.service}
              renderInput={(params) => <TextField {...params} label="Product" name={`items[${index}].service`}
              onChange={(event) => {setValue(`items[${index}].service`, event.target.value); calcTaxes();}}
               />}
              onChange={(event, value) => {
                handleList(value, index);
                // setValue(`items[${index}].service`, event.target.value);
              }}
              />
              <RHFTextField
                size="small"
                type="number"
                name={`items[${index}].quantity`}
                label="Quantity"
                onChange={(event) => {setValue(`items[${index}].quantity`,Number(event.target.value));calcTaxes()}}
                sx={{ maxWidth: { md: 96 } }}
              />

              <RHFTextField
                disabled
                size="small"
                type="number"
                name={`items[${index}].price`}
                label="Price"
                onChange={(event) => setValue(`items[${index}].price`, Number(event.target.value))}
                InputProps={{
                  startAdornment: <InputAdornment position="start">GH₵</InputAdornment>,
                }}
                sx={{ maxWidth: { md: 136 } }}
              />

              <RHFTextField
                size="small"
                type="number"
                name={`items[${index}].discount`}
                label="Discount"
                onChange={(event) => {setValue(`items[${index}].discount`, Number(event.target.value));calcDiscounts();}}
                InputProps={{
                  startAdornment: <InputAdornment position="start">GH₵</InputAdornment>,
                }}
                sx={{ maxWidth: { md: 136 } }}
              />
              <RHFTextField
                disabled
                size="small"
                type="number"
                name={`items[${index}].tax`}
                label="Tax"
                onChange={(event) => setValue(`items[${index}].tax`, Number(event.target.value))}
                InputProps={{
                  startAdornment: <InputAdornment position="start">GH₵</InputAdornment>,
                }}
                sx={{ maxWidth: { md: 136 } }}
              />


              {/* <RHFTextField
                disabled
                size="small"
                name={`items[${index}].title`}
                onChange={(event) => setValue(`items[${index}].category`, event.target.value)}
                label="Title"
                InputLabelProps={{ shrink: true }}
              />
 */}
              <RHFTextField
                disabled
                size="small"
                name={`items[${index}].description`}
                onChange={(event) => setValue(`items[${index}].desctiption`, event.target.value)}
                label="Description"
                InputLabelProps={{ shrink: true }}
              />


              <RHFTextField
                disabled
                size="small"
                name={`items[${index}].total`}
                label="Total"
                value={fNumber(values.items[index].quantity * values.items[index].price)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">GH₵</InputAdornment>,
                }}
                sx={{ maxWidth: { md: 156 } }}
              />

            </Stack>

            <Button
              size="small"
              color="error"
              startIcon={<Iconify icon="eva:trash-2-outline" />}
              onClick={() => handleRemove(index)}
            >
              Remove
            </Button>
          </Stack>
        ))}
      </Stack>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

      <Stack
        spacing={2}
        direction={{ xs: 'column-reverse', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
      >
        <Button size="small" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAdd} sx={{ flexShrink: 0 }}>
          Add more
        </Button>

        <Stack spacing={2} justifyContent="flex-end" direction={{ xs: 'column', md: 'row' }} sx={{ width: 1 }}>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Typography variant="h6">Sub Total: ₵{subtotal}</Typography>
              <Typography variant="h6">Discount: ₵{discounts}</Typography>
              <Typography variant="h6">Tax: ₵{taxes}</Typography>
              <Typography variant="h6">Grand Total: ₵{subtotal - discounts - taxes}</Typography>
            </Box>
          </Grid>

        </Stack>
      </Stack>
    </Box>
  );
}
