import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Card, CardHeader, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  EcommerceWidgetSummary,
  EcommerceLatestShops,
} from '../../../../sections/@dashboard/general/e-commerce';

import {
  AnalyticsTopShops,
} from '../../../../sections/@dashboard/general/analytics';

import Page from '../../../Page';
import { useShops, useInvoices, useStocks } from '../../../../hooks/owner';
// ----------------------------------------------------------------------

const title="Most Sales Shops";
const subheader="";
const limitData = [5, 10, 25, 50, 100];


export default function SalesShopStatistics() {

  const [limit, setLimit] = useState(5);
  
  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  } 

  const theme = useTheme();

  const ownerId = useSelector((state) => state.firebase.auth).uid;
  const shopData = useShops ({
    startAfter: undefined,
    limit: 20,
    filterName: '',
  });
  const invoiceData = useInvoices ({
    startAfter: undefined,
    limit: 1000,
    filterName: '',
  });
  const stockData = useStocks ({
    startAfter: undefined,
    limit: 1000,
    filterName: '',
  });

  const totalBalance = stockData.data.filter(item=>item.ownerId === ownerId).reduce((prev, next)=> prev + +next.storeQuantity * next.salesPrice, 0);

  const allShops = [];
  const stockBalance = [];
  const stockLabels = [];
  stockBalance.push(totalBalance);
  stockLabels.push(0);
  let tBalance = totalBalance;

  shopData.data.filter(item => item.ownerId === ownerId).forEach ((item, index)=>{
    let totalCount = 0;
    let totalAmount = 0;
    invoiceData.data.sort((a, b)=>a.createDate<b.createDate?1:-1).forEach(invoiceItem=>{
      invoiceItem.items.forEach(stockItem=> {
        if (item.shopName === stockItem.shopName) {
          totalCount += stockItem.quantity;
          totalAmount += stockItem.total;
        }
      });
      tBalance -= invoiceItem.totalPrice;
      stockBalance.push(totalBalance);
      stockLabels.push(index + 1);
    });
    allShops.push({
      shopData: item,
      count: totalCount,
      amount: totalAmount,
    });
  });

  const topShops = allShops.sort((a, b)=> a.amount < b.amount ? 1: -1);
  const chartDataAmount = [];
  const chartDataCount = [];
  topShops.forEach(item=> {
    chartDataAmount.push(
      {
        label: item.shopData.shopName,
        value: item.amount,
      }
    );
    chartDataCount.push(
      {
        label: item.shopData.shopName,
        value: item.count,
      }
    );
  });


  return (
    <Page>

    <Card container spacing={3}>

    <CardHeader
      title={title}
      subheader={subheader}
      action={
        <TextField
          select
          fullWidth
          value={limit}
          SelectProps={{ native: true }}
          onChange={handleChangeLimit}
          sx={{
            '& fieldset': { border: '0 !important' },
            '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
            '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
            '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 },
          }}
        >
          {limitData.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </TextField>
      }
    />
      <Grid container spacing={3} >
          <Grid item xs={12} md={6} lg={3}>
            <EcommerceWidgetSummary
              title="Stock Balance"
              percent={-0.1}
              total={totalBalance}
              chartColor={theme.palette.chart.green[0]}
              // chartData={stockBalance}
            />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
                <EcommerceLatestShops title="" list={topShops.slice(0, limit)} />
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
            <AnalyticsTopShops
              title=""
              subheader=""
              chartDataAmount={chartDataAmount.slice(0, limit)}
              chartDataCount={chartDataCount.slice(0, limit)}
            />
          </Grid>
      </Grid> 
      </Card>
    </Page>    

  );
}
