import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import InvoiceNewEditForm from '../../sections/@dashboard/invoice/new-edit-form';
import { useInvoices } from '../../hooks/owner';
import ShopSelect from './ShopSelect';

// ----------------------------------------------------------------------

export default function InvoiceEdit() {
  const { themeStretch } = useSettings();
  const [shopName, setShop] = useState('All');

  const { id } = useParams();

  const invoiceData = useInvoices({
    startAfter: undefined,
    limit: 1000,
    filterName: null
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (tableData.length === 0) setTableData(invoiceData.data);
  }, [invoiceData]);

  // const currentInvoice = _invoices.find((invoice) => invoice.id === id);
  const currentInvoice = tableData.find((invoice) => invoice.id === id);

  // currentInvoice.createDate = new Date(currentInvoice.createDate.seconds * 1000);
  // currentInvoice.dueDate = new Date(currentInvoice.dueDate.seconds * 1000);

  return currentInvoice!==undefined ? (
    <Page title="Invoices: Edit">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Edit invoice"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Invoices', href: PATH_DASHBOARD.invoice.list },
            { name: `INV-${currentInvoice?.invoiceTo.customerId}` || '' },
          ]}
        />

        <InvoiceNewEditForm isEdit currentInvoice={currentInvoice} />
      </Container>
    </Page>
  ):
  (
    <Page title="Invoices: Create">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Create invoice"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Invoices', href: PATH_DASHBOARD.invoice.list },
            { name: 'Create Invoice' },
          ]}
        />

        <ShopSelect setShop={setShop}/>
        <InvoiceNewEditForm isEdit currentInvoice={currentInvoice}  selectedShopName={shopName}/>
      </Container>
    </Page>
  )
  ;
}
