import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import { isEmpty } from 'react-redux-firebase';
// material
import {
  Container,
  Stack,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  CircularProgress,
  Button,
  Box,
  TablePagination
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// components
import Label from '../../../components/Label';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
//
import {
  ListHead,
  ListToolbar,
  DeleteDialog,
  StatusDialog
} from '../../../components/_dashboard/super_admin/@general';
//
import { AddSubscriptionsIndividual } from '../../../components/_adds/super_admin';
//
import { useSubscriptionsIndividual } from '../../../hooks/super_admin';
//
import {
  updateIndividual,
  removeIndividual
} from '../../../redux/slices/super_admin/subscriptions';

// -----------------------------------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'quantity', label: 'Quantity', alignRight: false },
  { id: '' }
];

export default function SubscriptionsIndividual() {
  const { searchedSubscriptionsTopups } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { subscriptionTopupsCount } = useSelector((state) => state.firebase.profile);

  // ---------------------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [cursor, setCursor] = useState(undefined);
  const [selectedIndividual, setSelectedIndividual] = useState(null);
  const [isIndividualEditOpen, setIsIndividualEditOpen] = useState(false);
  const [isIndividualStatusOpen, setIsIndividualStatusOpen] = useState(false);
  const [isIndividualDeleteOpen, setIsIndividualDeleteOpen] = useState(false);
  const subscriptionsIndividual = useSubscriptionsIndividual({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  // -----------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(subscriptionsIndividual.data[subscriptionsIndividual.data.length - 1].createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteIndividual = (item) => {
    setSelectedIndividual(item);
    setIsIndividualDeleteOpen(true);
  };

  const handleEditIndividual = (e, item) => {
    e.stopPropagation();
    setSelectedIndividual(item);
    setIsIndividualEditOpen(true);
  };

  const handleChangeStatus = (item) => {
    setSelectedIndividual(item);
    setIsIndividualStatusOpen(true);
  };

  // -----------------------------------------------------------------

  useEffect(() => {
    if (!isIndividualEditOpen && !isIndividualDeleteOpen && !isIndividualStatusOpen) {
      setSelectedIndividual(null);
    }
  }, [isIndividualEditOpen, isIndividualDeleteOpen, isIndividualStatusOpen]);

  useEffect(() => {
    if (requesting.searchedSubscriptionsTopups) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedSubscriptionsTopups]);

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName
            ? searchedSubscriptionsTopups?.length
            : subscriptionsIndividual.data?.length
        )
      : 0;

  return (
    <Page title="Super Admin: Subscription Individual | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Individual
          </Typography>
          <AddSubscriptionsIndividual
            isOpen={isIndividualEditOpen}
            setIsOpen={setIsIndividualEditOpen}
            selectedItem={selectedIndividual}
          >
            <Button variant="contained" component="div" startIcon={<Icon icon={plusFill} />}>
              Add
            </Button>
          </AddSubscriptionsIndividual>
        </Stack>

        <Card>
          {!!subscriptionsIndividual.data.length && (
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedSubscriptionsTopups}
            />
          )}
          {subscriptionsIndividual.loading || requesting.searchedSubscriptionsTopups ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!subscriptionsIndividual.data.length ? (
                'Is empty'
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={
                          filterName
                            ? searchedSubscriptionsTopups?.length || 0
                            : subscriptionTopupsCount || 0
                        }
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName
                          ? searchedSubscriptionsTopups || subscriptionsIndividual.data
                          : subscriptionsIndividual.data
                        )
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { name, isActive, price, quantity } = row;

                            return (
                              <TableRow key={row.id} tabIndex={-1}>
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{name}</TableCell>
                                <TableCell align="left">
                                  <Label
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleChangeStatus(row)}
                                    variant="ghost"
                                    color={(!isActive && 'error') || 'success'}
                                  >
                                    {isActive ? sentenceCase('active') : sentenceCase('inactive')}
                                  </Label>
                                </TableCell>
                                <TableCell align="left">{`₵${price}`}</TableCell>
                                <TableCell align="left">{quantity}</TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    onClick={(e) => handleEditIndividual(e, row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    variant="contained"
                                    component="div"
                                    color="error"
                                    onClick={() => handleDeleteIndividual(row)}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedSubscriptionsTopups) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedSubscriptionsTopups && !!subscriptionsIndividual.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={
                filterName ? searchedSubscriptionsTopups?.length || 0 : subscriptionTopupsCount || 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {isIndividualDeleteOpen && (
          <DeleteDialog
            isOpen={isIndividualDeleteOpen}
            setIsOpen={setIsIndividualDeleteOpen}
            selectedItem={selectedIndividual}
            delFunc={removeIndividual}
          />
        )}
        {isIndividualStatusOpen && (
          <StatusDialog
            isOpen={isIndividualStatusOpen}
            setIsOpen={setIsIndividualStatusOpen}
            selectedItem={selectedIndividual}
            statusFunc={updateIndividual}
          />
        )}
      </Container>
    </Page>
  );
}
