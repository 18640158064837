import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// @mui
import { Box, Grid, Card, Stack, Typography, TextField, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// utils
import { fData } from '../../../../utils/formatNumber';
// _mock
// import { countries } from '../../../../_mock';
// components
import { UploadAvatar } from '../../../../components/upload';

import { updateAccount } from '../../../../redux/slices/owner/account';

// ----------------------------------------------------------------------

const COUNTRIES = [
  {
    name: 'Ghana',
    value: 'ghana'
  }
];

export default function AccountGeneral() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const uid = useSelector((state) => state.firebase.auth.uid);
  const profile = useSelector((state) => state.firebase.profile);

  const UpdateUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    // phoneNumber: Yup.string()
    //   .max(9, 'Invalid phone number')
    //   .min(9, 'Invalid phone number')
    //   .required('Phone number is required'),
    companyName: Yup.string().required('Company name is required')
  });

  const defaultValues = {
    firstName: profile?.firstName || '',
    lastName: profile?.lastName || '',
    companyName: profile?.companyName || '',
    phoneNumber: profile?.phoneNumber || '',
    country: profile?.country || '',
    address: profile?.address || '',
    city: profile?.city || '',
    profileImage: profile?.profileImage || ''
  };

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await dispatch(updateAccount({ accountInfo: values, id: uid }));
        setSubmitting(false);
        enqueueSnackbar('Account updated successfully', { variant: 'success' });
      } catch (error) {
        setSubmitting(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        formik.setFieldValue(
          'profileImage',
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
      }
    },
    [formik]
  );

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
              <UploadAvatar
                accept="image/*"
                maxSize={3145728}
                onDrop={handleDrop}
                file={formik.values.profileImage}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary'
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 3,
                  columnGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
                }}
              >
                <TextField
                  label="First name"
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
                <TextField
                  label="Last name"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
                <TextField fullWidth select label="Country" {...getFieldProps('country')}>
                  {COUNTRIES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="City"
                  {...getFieldProps('city')}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                />

                <TextField
                  fullWidth
                  label="Company name"
                  {...getFieldProps('companyName')}
                  error={Boolean(touched.companyName && errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                />
                <TextField
                  fullWidth
                  disabled
                  label="Phone number"
                  {...getFieldProps('phoneNumber')}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Box>

              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <TextField
                  fullWidth
                  label="Address"
                  multiline
                  rows={3}
                  {...getFieldProps('address')}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
