import { useSelector } from 'react-redux';
// @mui
import { Grid, Stack } from '@mui/material';
//
import ProfileAbout from './ProfileAbout';
// import ProfileFollowInfo from './ProfileFollowInfo';
import ProfileSocialInfo from './ProfileSocialInfo';

// ----------------------------------------------------------------------

export default function Profile() {
  const myProfile = useSelector((state) => state.firebase.profile);
  return (
    <Grid container spacing={3}>
      <Grid item md={7}>
        <Stack spacing={3}>
          {/* <ProfileFollowInfo profile={myProfile} /> */}
          <ProfileAbout profile={myProfile} />
        </Stack>
      </Grid>
      <Grid item md={5}>
        <Stack spacing={3}>
          {/* <ProfileFollowInfo profile={myProfile} /> */}
          <ProfileSocialInfo profile={myProfile} />
        </Stack>
      </Grid>
    </Grid>
  );
}
