import * as Yup from 'yup';
import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import closeFill from '@iconify/icons-eva/close-fill';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { /* alpha, */ styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  Box,
  Container,
  TextField,
  InputAdornment,
  Stack,
  Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Toaster from '../../Toaster';

import { addStock, updateStock } from '../../../redux/slices/owner/stocks';
import { useShops, useCategories } from '../../../hooks/owner';
import { checkForRole } from '../../../utils/checkAuthUserRole';

import UploadDropzone from '../../UploadDropzone';


AddStockDialog.propTypes = {
  children: PropTypes.node,
  selectedStock: PropTypes.object,
  isOwnerOpen: PropTypes.bool,
  setIsOwnerOpen: PropTypes.func
};

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ----------------------------------------------------------------------

const ChildrenButtonStyle = styled(Button)(() => ({
  width: '100%',
  padding: 0
}));

// ----------------------------------------------------------------------

export default function AddStockDialog({ children, selectedStock, isOwnerOpen, setIsOwnerOpen }) {


  const sizes = ['Small', 'Medium', 'Large', 'Extra Large'];
  const genders = ['Male', 'Female'];
  const receivers = ['Child', 'Adult'];
  const shops = ['No', 'Yes'];
  const stores = useShops({
    startAfter: 0,
    limit: 50,
   filterName: '',
  });

  const allCategories = useCategories({
    startAfter: 0,
    limit: 50
  })
  const currentUser = useSelector((state) => state.firebase.profile);

  const { token, ownerId } = useSelector((state) => state.firebase.profile);
  const currUserId = useSelector((state) => state.firebase.auth.uid);

  const { identifier } = token && checkForRole(token);

  const uid = identifier === 'staff' ? ownerId : currUserId;
  const shopNames = stores.data.filter(item=>item.ownerId === uid).map(person => person.shopName);
  const categories = allCategories.data.map(item => item.name);
//  const shopNames = [];
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState(categories[0]);
  const [size, setSize] = useState(sizes[0]);
  const [gender, setGender] = useState(genders[0]);
  const [receiver, setReceiver] = useState(receivers[0]);
  const [isShop, setIsShop] = useState(shops[0]);
  const [imagesCount, setImagesCount] = useState(4);
  const [shopName, setShopName] = useState(null);
  const [owner, setOwner] = useState(currentUser.phoneNumber);
  const [initialValues] = useState({
      itemName: '',
      shopName: '',
      costPrice: 0,
      salesPrice: 0,
      storeQuantity: '',
      alertQuantity: '',
      tax: 0,
      vat: 0,
      nhis: 0,
      isShop: '',
      fabrics: [],
      description: '',
      owner: '',
      ownerId: '',
  });

  const stockContainerRef = useRef(null);
  const toastRef = useRef();

  // console.log("CUrrent User", currentUser);

  // -----------------------------------------------------------------

  const dispatch = useDispatch();
  // -----------------------------------------------------------------

//  const shopNames = shopdatas.map(person => person.shopName);

  const RegisterSchema = Yup.object().shape({
    itemName: Yup.string().required('ItemName is required'),
    // costPrice: Yup.number().required(),
    // salesPrice: Yup.number().required(),
    storeQuantity: Yup.string().required('Store Quantity is required'),
    // alertQuantity: Yup.string()
    //   .min(6, 'AlertQuantity should be at least 6 characters')
    //   .required('Alert Quantity is required')
  });

  const formik = useFormik({
    initialValues,
    validationSchema: RegisterSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        if (selectedStock) {
          const stockInfo = {
            ...selectedStock,
            ...values
          };

          stockInfo.shopName = shopName;
          stockInfo.gender = gender;
          stockInfo.receiver = receiver;
          stockInfo.category = category;
          // stockInfo.owner = currentUser.phoneNumber;
          stockInfo.ownerId = uid;
          await dispatch(updateStock(stockInfo));
          toastRef.current.handleOpen('Stock updated successfully');
        } else {
          const stockInfo = {
            ...values,
            category,
            size,
            gender,
            receiver,
            isShop,
            shopName,
            owner,
            ownerId,
          };
          await dispatch(addStock(stockInfo));
          toastRef.current.handleOpen('Stock added successfully');
        }
        // reset form
        resetForm();
        // clsoe dialog
        handleClose();
      } catch (error) {
        stockContainerRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleCategory = (value) => {
    setCategory(value);
  };

  const handleSize = (value) => {
    setSize(value);
  };

  const handleGender = (value) => {
    setGender(value);
  };

  const handleReceiver = (value) => {
    setReceiver(value);
  };

  const handleShop = (value) => {
    setIsShop(value);
  };

  const handleShopName = (value) => {
    setShopName(value);
  };
  // -----------------------------------------------------------------

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError(null);
    setOpen(false);
    if (setIsOwnerOpen) setIsOwnerOpen(false);
  };

  // -----------------------------------------------------------------

  // watch isSewOpen change
  useEffect(() => {
    if (isOwnerOpen) {
      setOpen(true);
      if (selectedStock) {
        formik.setValues(selectedStock);
      }
    } else {
      formik.resetForm();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOwnerOpen]);

  useEffect(() => {
    if (isShop === shops[0]) setImagesCount(4);
    else setImagesCount(1);
  }, [isShop]);

  useEffect(() => {
    if (selectedStock && Object.keys(selectedStock).length > 0) {
      setIsShop(selectedStock?.isShop);
      setShopName(selectedStock?.shopName);
      setGender(selectedStock?.gender);
      setCategory(selectedStock?.category);
    }
  }, [selectedStock]);

  // -----------------------------------------------------------------

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <ChildrenButtonStyle onClick={handleClickOpen}>{children}</ChildrenButtonStyle>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <div ref={stockContainerRef} style={{ overflowY: 'auto' }}>
          <AppBar sx={{ position: 'sticky' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Icon icon={closeFill} width={24} height={24} />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {`${selectedStock ? 'Edit' : 'Add'} Stock`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Container maxWidth="sm" sx={{ pb: 5 }}>
            <Box sx={{ pb: 3, pt: 4 }}>
              <Typography variant="h4">{`${selectedStock ? 'Edit' : 'Add New'} Stock`}</Typography>
            </Box>
            {error && (
              <Alert sx={{ mt: -1.5, mb: 3 }} severity="error">
                {error}
              </Alert>
            )}
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack>
                  <Grid container style={{ marginBottom: 10 }}>
                    <Grid item xs={12} sm={4} sx={{ pr: 1 }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={categories}
                        defaultValue={category}
                        renderInput={(params) => <TextField {...params} label="Categories" />}
                        onChange={(event, value) => handleCategory(value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ pr: 1 }}>
                      <TextField
                        fullWidth
                        sx={{ mb: 3 }}
                        type="text"
                        label="Item Name"
                        {...getFieldProps('itemName')}
                        error={Boolean(touched.itemName && errors.itemName)}
                        helperText={touched.itemName && errors.itemName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ pr: 1 }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={shopNames}
                        defaultValue={shopName}
                        renderInput={(params) => <TextField {...params} label="Shop Name" />}
                        onChange={(event, value) => handleShopName(value)}
                        error={Boolean(touched.shopName && errors.shopName)}
                        helperText={touched.shopName && errors.shopName}
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: 10 }}>
                    <Grid item xs={12} sm={4} sx={{ pr: 1 }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={genders}
                        defaultValue={gender}
                        renderInput={(params) => <TextField {...params} label="Gender" />}
                        onChange={(event, value) => handleGender(value)}
                        error={Boolean(touched.gender && errors.gender)}
                        helperText={touched.gender && errors.gender}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ pr: 1 }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={sizes}
                        defaultValue={size}
                        renderInput={(params) => <TextField {...params} label="Size" />}
                        onChange={(event, value) => handleSize(value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ pr: 1 }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={receivers}
                        defaultValue={receiver}
                        renderInput={(params) => <TextField {...params} label="Child or Adult" />}
                        onChange={(event, value) => handleReceiver(value)}
                        error={Boolean(touched.receiver && errors.receiver)}
                        helperText={touched.receiver && errors.receiver}

                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginBottom: 10 }}>
                    <Grid item xs={12} sm={6} sx={{ pr: 1 }}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Cost Price"
                        {...getFieldProps('costPrice')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₵</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ pr: 1 }}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Sales Price"
                        {...getFieldProps('salesPrice')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₵</InputAdornment>
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginBottom: 10 }}>
                    <Grid item xs={12} sm={6} sx={{ pr: 1 }}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Store Quantity"
                        {...getFieldProps('storeQuantity')}
                        error={Boolean(touched.storeQuantity && errors.storeQuantity)}
                        helperText={touched.storeQuantity && errors.storeQuantity}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ pr: 1 }}>
                      <TextField
                        fullWidth
                        sx={{ mb: 3 }}
                        type="text"
                        label="Alert Quantity"
                        {...getFieldProps('alertQuantity')}
                        error={Boolean(touched.alertQuantity && errors.alertQuantity)}
                        helperText={touched.alertQuantity && errors.alertQuantity}
                      />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} sm={3} sx={{ pr: 1 }}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Tax"
                        {...getFieldProps('tax')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">%</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} sx={{ pr: 1 }}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Vat"
                        {...getFieldProps('vat')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">%</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} sx={{ pr: 1 }}>
                      <TextField
                        fullWidth
                        type="number"
                        label="NHIS"
                        {...getFieldProps('nhis')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">%</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} sx={{ pr: 1 }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={shops}
                        defaultValue={isShop}
                        renderInput={(params) => <TextField {...params} label="Shop" />}
                        style={{ marginBottom: 10 }}
                        onChange={(event, value) => handleShop(value)}
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="subtitle2" component="p">
                    Upload Picture
                  </Typography>
                  <UploadDropzone
                    images={selectedStock ? selectedStock.fabrics : null}
                    maxFiles={imagesCount}
                    onChange={(files) => {
                      formik.setFieldValue('fabrics', files);
                    }}
                  />

                  {isShop === shops[0] && (
                    <TextField
                      label="Description"
                      multiline
                      rows={4}
                      {...getFieldProps('description')}
                      style={{ marginBottom: 10 }}
                    />
                  )}

                  <LoadingButton
                    id="sign-up-button"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {selectedStock ? 'Update Stock' : 'Add Stock'}
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Container>
        </div>
      </Dialog>
      <Toaster ref={toastRef} />
    </div>
  );
}
