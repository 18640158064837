import * as Yup from 'yup';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { isLoaded } from 'react-redux-firebase';

import { signin } from '../../../redux/slices/auth';

import { getErrorMessage } from '../../../utils/firebaseError';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  // ----------------------------------------------------------------------

  const _isMounted = useRef(true);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.firebase.profile);

  // ----------------------------------------------------------------------

  const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .max(9, 'Invalid phone number')
      .min(9, 'Invalid phone number')
      .required('Phone number is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await dispatch(signin(values));
        resetForm();
        if (isLoaded(profile)) navigate('/dashboard', { replace: true });
      } catch (error) {
        setError(getErrorMessage(error) || error.message);
        window.scrollTo(0, 0);
      } finally {
        if (_isMounted.current) {
          setSubmitting(false);
        }
      }
    }
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(
    () => () => {
      _isMounted.current = false;
    },
    []
  );

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      {error && (
        <Alert sx={{ mt: -3, mb: 3 }} severity="error">
          {error}
        </Alert>
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="number"
            label="Phone Number"
            {...getFieldProps('phoneNumber')}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/reset-password">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
