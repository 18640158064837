import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const addInvoice = createAsyncThunk(
  'invoices/add',
  async ({invoiceData, ownerId}, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    // const superAdminId = firebase.auth().currentUser.uid;

    const newInvoicesRefId = firestore.collection('invoices').doc().id;
    const timestamp = firestore.FieldValue.serverTimestamp();

    // if (invoiceData.fabrics.length) {
    //   invoiceData.fabrics = await imagesUrlArrayGet(firebase, newInvoicesRefId, invoiceData.fabrics);
    // }

    // console.log("Delete All Invoices");

    // // try {
    //   firestore.collection("invoices")
    //   .get()
    //   .then(res => {
    //     res.forEach(element => {
    //       element.ref.delete();
    //     });
    //   });
    // // }

    try {
      firestore.set(
        { collection: 'invoices', doc: newInvoicesRefId },
        {
          ...invoiceData,
          ownerId,
          invoiceData: {
            createdAt: timestamp,
            updatedAt: timestamp
          },
        }
      );
      invoiceData.items.forEach(item=> {
        firestore.collection('stocks')
        .doc(item.id)
        .get()
        .then(doc => {

          firestore.update(
            { collection: 'stocks', doc: item.id },
            {
              storeQuantity: Number(doc.data().storeQuantity) - Number(item.quantity),
            }
          );
        });
      });
    }
    catch (err) {
      console.log(err);
    }

  }
);

export const invoiceDelete = createAsyncThunk(
  'invoices/deleteAll',
  async (invoiceData, { extra: { getFirestore, getFirebase } }) => {
    // console.log(invoiceData);
    const firestore = getFirestore();
    const firebase = getFirebase();

    console.log("Delete All Invoices");

    firestore.collection("invoices")
    .get()
    .then(res => {
      res.forEach(element => {
        element.ref.delete();
      });
    });


  }
);



export const addPayment = createAsyncThunk(
  'invoices/addPayment',
  async ([id, method, number, amount], { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const newInvoicesRefId = firestore.collection('invoicePayments').doc().id;
    const timestamp = firestore.FieldValue.serverTimestamp();

    console.log("paymentData====>", id, method, number, amount);

    firestore.set(
      { collection: 'invoicePayments', doc: newInvoicesRefId },
      {
        invoiceId: id,
        paymentMethod: method,
        paymentNumber: (method==='Cash Number'?null:number),
        paymentAmount: amount,
        createdAt: timestamp,
      }
    );
  }
);

export const changeInvoiceStatus = createAsyncThunk(
  'invoices/changeStatus',
  async (invoiceData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();
    const newInvoice = invoiceData[0];
    const paidAmount = invoiceData[1];

    const invoice = firestore.collection("invoices").doc(newInvoice.id).update({
      status: 'paid',
      paidAmount: Number(paidAmount),
    });

    // const invoice = await firestore.update({ collection: 'invoices', doc: newInvoice.id }, invoiceData);
    // console.log(invoice);
    return invoice;
  }
);

export const changeInvoiceStatusToPartial = createAsyncThunk(
  'invoices/changeStatusToPartial',
  async (invoiceData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();
    const newInvoice = invoiceData[0];
    const paidAmount = invoiceData[1];

    const invoice = firestore.collection("invoices").doc(newInvoice.id).update({
      status: 'partial',
      paidAmount: Number(paidAmount),
    });

    // const invoice = await firestore.update({ collection: 'invoices', doc: newInvoice.id }, invoiceData);
    // console.log(invoice);
    return invoice;
  }
);

export const restoreStocks = createAsyncThunk(
  'invoices/restoreStocks',
  async (invoiceData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const { id } = invoiceData;
    const timestamp = new Date();

    const invoices = await firestore.collection('invoices')
    .doc(id)
    .get()
    .then(data=> {
      data.data().items.forEach(item=> {
        firestore.collection('stocks')
        .doc(item.id)
        .get()
        .then(doc => {
          const storeQuantity = doc.data().storeQuantity;
          firestore.update(
            { collection: 'stocks', doc: item.id },
            {
              storeQuantity: Number(storeQuantity) + Number(item.quantity),
            }
          );
        });
      });
    });


  }
);


export const updateInvoice = createAsyncThunk(
  'invoices/update',
  async (invoiceData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const { id } = invoiceData;
    const timestamp = new Date();

    const invoice = await firestore.update(
      { collection: 'invoices', doc: id },
      {
        ...invoiceData,
        invoiceData: {
          createdAt: timestamp,
        }
      }
    );


    invoiceData.items.forEach(item=> {
      firestore.collection('stocks')
      .doc(item.id)
      .get()
      .then(doc => {
        const storeQuantity = doc.data().storeQuantity;
        firestore.update(
          { collection: 'stocks', doc: item.id },
          {
            storeQuantity: Number(storeQuantity) - Number(item.quantity),
          }
        );
      });
    });

    return invoice;

  }
);

export const removeInvoice = createAsyncThunk(
  'invoices/remove',
  async (id, { extra: { getFirestore } }) => {
    const firestore = getFirestore();
    // firestore.collection("invoices")
    // .get()
    // .then(res => {
    //   res.forEach(element => {
    //     element.ref.delete();
    //   });
    // });
    // firestore.delete({collection: 'invoices', doc: id});
    const invoiceData = firestore.collection('invoices')
    .doc(id)
    .get()
    .then(invoice=> {
      const invoiceData = invoice.data();
      invoiceData.items.forEach(item=> {
        firestore.collection('stocks')
        .doc(item.id)
        .get()
        .then(doc => {
          const storeQuantity = doc.data().storeQuantity;
          firestore.update(
            { collection: 'stocks', doc: item.id },
            {
              storeQuantity: Number(storeQuantity) + Number(item.quantity),
            }
          );
        });
        firestore.delete({ collection: 'invoices', doc: id });
      });
    });


  }
);

const initialState = {
  error: null,
  is_added: false
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addInvoice.fulfilled, (state) => {
      state.error = null;
      state.is_added = true;
    });

    builder.addCase(addInvoice.rejected, (state, action) => {
      state.error = action.error;
      state.is_added = false;
    });

    // ----------------------------------------------------------------

    builder.addCase(changeInvoiceStatus.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(changeInvoiceStatus.rejected, (state, action) => {
      state.error = action.error;
    });

    builder.addCase(changeInvoiceStatusToPartial.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(changeInvoiceStatusToPartial.rejected, (state, action) => {
      state.error = action.error;
    });
    // ----------------------------------------------------------------

    builder.addCase(updateInvoice.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(updateInvoice.rejected, (state, action) => {
      state.error = action.error;
    });
    builder.addCase(addPayment.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addPayment.rejected, (state, action) => {
      state.error = action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(removeInvoice.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(removeInvoice.rejected, (state, action) => {
      state.error = action.error;
    });

    builder.addCase(invoiceDelete.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(invoiceDelete.rejected, (state, action) => {
      state.error = action.error;
    });
    builder.addCase(restoreStocks.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(restoreStocks.rejected, (state, action) => {
      state.error = action.error;
    });

  }
});

export default invoicesSlice.reducer;
