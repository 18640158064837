import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch} from 'react-redux'
// @mui
import { Grid, TextField, Box, Radio, Typography, Dialog, Stack, RadioGroup} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { changeInvoiceStatus, changeInvoiceStatusToPartial, addPayment} from '../../../../redux/slices/owner/invoices';


PaymentDialog.propTypes = {
    invoice: PropTypes.object,
    Open: PropTypes.bool,
    handleClose: PropTypes.func,
    setPay: PropTypes.func,
    totalPrice: PropTypes.func,
};


export default function PaymentDialog ({ setPay, Open, handleClose, invoice, totalPrice}) {


    // const handleOpenMenu = (event) => {
    //   setOpenMenuActions(event.currentTarget);
    // };

    // const handleCloseMenu = () => {
    //   setOpenMenuActions(null);
    // };

    const dispatch = useDispatch();
    const [selectedPay, setSelected] = useState(undefined);
    const [payNumber, setPayNumber] = useState('Number');
    const [number, setNumber] = useState(0);
    const [amount, setAmount] = useState(0);


    const onSubmit = async () => {
        let paid = 0;
        if (invoice.paidAmount === undefined || Number.isNaN(invoice.paidAmount)) {
            paid = amount;
        }
        else paid = (+amount) + (+invoice.paidAmount);
        if (paid >= totalPrice) {
            await dispatch(changeInvoiceStatus([invoice, paid]));
        }
        else {
            await dispatch(changeInvoiceStatusToPartial([invoice, paid]));
        }
        setPay(true);
        dispatch(addPayment([invoice.id, payNumber, number, amount]));
        handleClose();
        // Refresh();
    };

    const handleChange = ev => {
      setSelected(ev.target.value);
      if (ev.target.value === "Cash") {
          document.getElementById('Number').style.display = "none";
      }
      else {
          document.getElementById('Number').style.display = "block";
      }
      const str = `${ev.target.value} Number`;
      setPayNumber(str);
    };

    const changeNumber = ev => {
        setNumber(ev.target.value);
    }

    const changeAmount = ev => {
        setAmount(ev.target.value);
    }


    return (
        <Dialog open={Open} onClose = {handleClose}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2.5, px: 3 }}>
        <Typography variant="h6"> Paying by </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2.5, px: 3 }}>
        <Box>
            <FormControl name="pay-choose">
                <Grid item xs={12} sm={12} sx={{ pr: 1 }}>
                <RadioGroup onChange={handleChange} value={selectedPay}>
                    <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
                    <FormControlLabel value="Cheque" control={<Radio />} label="Cheque" />
                    <FormControlLabel value="Mobile Money" control={<Radio />} label="Mobile Money" />
                    <FormControlLabel value="Bank Transfer" control={<Radio />} label="Bank Transfer" />
                </RadioGroup>
                </Grid>
                <Stack justifyContent="space-between" sx={{ py: 2.5 }}>
                {/* <TextField label="Outlined secondary" color="secondary" focused />
                <TextField label="Filled success" variant="filled" color="success" focused /> */}
                <Grid size="large" id="Number">
                    <TextField
                        fullWidth
                        sx={{ mb: 3 }}
                        type="number"
                        label={payNumber}
                        style={{ display: (payNumber === 'Cash Number'?'none':'block') }}
                        onChange = {changeNumber}
                        // hidden="true"
                        // {...getFieldProps('itemName')}
                        // error={Boolean(touched.itemName && errors.itemName)}
                        // helperText={touched.itemName && errors.itemName}
                    />
                </Grid>
                <Grid size="large">
                    <TextField
                        fullWidth
                        sx={{ mb: 3 }}
                        type="number"
                        label="Amount"
                        onChange = {changeAmount}
                        // {...getFieldProps('itemName')}
                        // error={Boolean(touched.itemName && errors.itemName)}
                        // helperText={touched.itemName && errors.itemName}
                    />
                </Grid>

                <LoadingButton
                    size="large"
                    variant="contained"
                    onClick={onSubmit}
                    disabled = {selectedPay === undefined || amount === 0  || (selectedPay !== 'Cash' && (number === 0))}
                >
                    Accept
                </LoadingButton>

                {/* <TextField
                    floatRight
                    id = "phoneNumber"
                    label="PhoneNumber"
                    variant="standard"
                    color="secondary"
                    hidden
                    focused
                />
                <TextField
                    floatRight
                    label="Payment"
                    variant="standard"
                    color="secondary"
                    focused
                /> */}
                </Stack>
            </FormControl>
        </Box>
        </Stack>


        </Dialog>
    );
}