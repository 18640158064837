import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { condenseText } from '../../utils/formatText';

const mergeLists = (ordered, storeAs, limit) => {
  let nextPage = false;
  let list = Object.entries(ordered).filter(([key]) => key.includes(storeAs));
  list = list.map((item, index) => {
    if (item[1].length - 1 === limit) {
      if (list.length - 1 === index) {
        nextPage = true;
      }
      return item[1].slice(0, -1);
    }
    return item[1];
  });
  return [[...new Map(list.flat().map((v) => [v.id, v])).values()], nextPage];
};

const useHook = ({ limit, startAfter, filterName }) => {
  const { uid } = useSelector((state) => state.firebase.auth);
  const storeAs = 'staff';
  const key = `${storeAs}/${startAfter || 0}`;
  const [query, setQuery] = useState(null);

  useEffect(() => {
    if (filterName) {
      if (Number.isNaN(parseInt(filterName, 10))) {
        const keyword = condenseText(filterName);
        setQuery({
          collection: 'staff',
          where: [
            ['ownerId', '==', uid],
            ['staffData.searchMatch', '>=', keyword],
            ['staffData.searchMatch', '<', `${keyword}z`]
          ],
          storeAs: 'searchedStaff'
        });
      } else {
        const parsedValue = filterName.toString();
        setQuery({
          collection: 'staff',
          where: [
            ['ownerId', '==', uid],
            ['staffData.phoneNumber', '>=', parsedValue],
            ['staffData.phoneNumber', '<', `${parsedValue}z`]
          ],
          limit: 20,
          storeAs: 'searchedStaff'
        });
      }
    } else {
      setQuery({
        collection: 'staff',
        where: [['ownerId', '==', uid]],
        orderBy: ['staffData.createdAt', 'desc'],
        storeAs: key,
        limit: limit + 1,
        startAfter
      });
    }
  }, [filterName, key, limit, startAfter, uid]);

  useFirestoreConnect(
    () =>
      query || {
        collection: 'staff',
        where: [['ownerId', '==', uid]],
        orderBy: ['staffData.createdAt', 'desc'],
        storeAs: key,
        limit: limit + 1,
        startAfter
      }
  );

  return useSelector(({ firestore: { status, ordered, errors } }) => {
    const loading =
      status.requesting[key] === undefined ||
      Object.entries(status.requesting).some(([key, value]) => key.includes(storeAs) && value);
    const error = Object.entries(errors).find(([key, value]) => key.includes(storeAs) && !!value);
    const [list, nextPage] = mergeLists(ordered, storeAs, limit);
    return {
      loading,
      error,
      data: list,
      nextPage
    };
  });
};

export default useHook;
