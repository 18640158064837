import * as Yup from 'yup';
import { useState, forwardRef, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { /* alpha, */ styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Container,
  TextField,
  Stack,
  Alert,
  InputAdornment
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
//
import Toaster from '../../Toaster';
//
import { addOwner, updateOwner } from '../../../redux/slices/super_admin/owners';
//
import { stringfyPhoneNumber } from '../../../utils/formatPhoneNumber';
import { condenseName } from '../../../utils/formatText';
import { getErrorMessage } from '../../../utils/firebaseError';

AddOwnerDialog.propTypes = {
  children: PropTypes.node,
  selectedOwner: PropTypes.object,
  isOwnerOpen: PropTypes.bool,
  setIsOwnerOpen: PropTypes.func
};

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ----------------------------------------------------------------------

const ChildrenButtonStyle = styled(Button)(() => ({
  width: '100%',
  padding: 0
}));

// ----------------------------------------------------------------------

export default function AddOwnerDialog({ children, selectedOwner, isOwnerOpen, setIsOwnerOpen }) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [initialValues] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    senderId: 'fashionspal',
    password: '123456',
    confirmPassword: '123456',
    companyName: '',
    address: '',
    profileImage: ''
  });

  // -----------------------------------------------------------------

  const ownerContainerRef = useRef(null);
  const toastRef = useRef();
  const dispatch = useDispatch();

  // -----------------------------------------------------------------

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    phoneNumber: selectedOwner
      ? Yup.string()
          .max(10, 'Invalid phone number')
          .min(10, 'Invalid phone number')
          .required('Phone number is required')
      : Yup.string()
          .max(9, 'Invalid phone number')
          .min(9, 'Invalid phone number')
          .required('Phone number is required'),
    senderId: Yup.string().max(11, 'Cannot send more than 11 characters'),
    password: !selectedOwner
      ? Yup.string()
          .min(6, 'Password should be at least 6 characters')
          .required('Password is required')
      : null,
    confirmPassword: !selectedOwner
      ? Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
      : null,
    companyName: Yup.string().required('Company name is required')
  });

  const formik = useFormik({
    initialValues,
    validationSchema: RegisterSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        if (selectedOwner) {
          const ownerInfo = {
            ...selectedOwner,
            ...values,
            phoneNumber: stringfyPhoneNumber(values.phoneNumber),
            searchMatch: condenseName(values.firstName, values.lastName)
          };

          if (selectedOwner.phoneNumber !== ownerInfo.phoneNumber) {
            ownerInfo.oldPhoneNumber = selectedOwner.phoneNumber;
          }

          await dispatch(updateOwner(ownerInfo));
          toastRef.current.handleOpen('Owner updated successfully');
        } else {
          const ownerInfo = {
            ...values,
            phoneNumber: stringfyPhoneNumber(values.phoneNumber),
            searchMatch: condenseName(values.firstName, values.lastName),
            sewingsCount: 0,
            customersCount: 0,
            totalIncome: 0,
            totalExpenditure: 0,
            isEnabled: true,
            role: 'owner'
          };
          await dispatch(addOwner(ownerInfo));
          toastRef.current.handleOpen('Owner added successfully');
        }
        // reset form
        resetForm();
        // clsoe dialog
        handleClose();
      } catch (error) {
        ownerContainerRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setError(getErrorMessage(error) || error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // -----------------------------------------------------------------

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError(null);
    setOpen(false);
    if (setIsOwnerOpen) setIsOwnerOpen(false);
  };

  // -----------------------------------------------------------------

  // watch isSewOpen change
  useEffect(() => {
    if (isOwnerOpen) {
      setOpen(true);
      if (selectedOwner) {
        formik.setValues(selectedOwner);
        if (!selectedOwner.senderId) formik.setFieldValue('senderId', 'fashionspal');
      }
    } else {
      formik.resetForm();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOwnerOpen]);

  // -----------------------------------------------------------------

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <ChildrenButtonStyle onClick={handleClickOpen}>{children}</ChildrenButtonStyle>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <div ref={ownerContainerRef} style={{ overflowY: 'auto' }}>
          <AppBar sx={{ position: 'sticky' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Icon icon={closeFill} width={24} height={24} />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {`${selectedOwner ? 'Edit' : 'Add'} Owner`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Container maxWidth="sm" sx={{ pb: 5 }}>
            <Box sx={{ pb: 3, pt: 4 }}>
              <Typography variant="h4">{`${selectedOwner ? 'Edit' : 'Add New'} Owner`}</Typography>
            </Box>
            {error && (
              <Alert sx={{ mt: -1.5, mb: 3 }} severity="error">
                {error}
              </Alert>
            )}
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 3 }}>
                    <TextField
                      fullWidth
                      label="First name"
                      {...getFieldProps('firstName')}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />

                    <TextField
                      fullWidth
                      autoComplete="username"
                      label="Last name"
                      {...getFieldProps('lastName')}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 3 }}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Phone number"
                      {...getFieldProps('phoneNumber')}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />

                    <TextField
                      fullWidth
                      label="Sender ID"
                      {...getFieldProps('senderId')}
                      error={Boolean(touched.senderId && errors.senderId)}
                      helperText={touched.senderId && errors.senderId}
                    />
                  </Stack>

                  {!selectedOwner ? (
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 3 }}>
                      <TextField
                        fullWidth
                        autoComplete="new-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        {...getFieldProps('password')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={() => setShowPassword((prev) => !prev)}
                              >
                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />

                      <TextField
                        fullWidth
                        autoComplete="new-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        label="Confirm Password"
                        {...getFieldProps('confirmPassword')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={() => setShowConfirmPassword((prev) => !prev)}
                              >
                                <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                      />
                    </Stack>
                  ) : null}

                  <TextField
                    fullWidth
                    sx={{ mb: 3 }}
                    type="text"
                    label="Company name"
                    {...getFieldProps('companyName')}
                    error={Boolean(touched.companyName && errors.companyName)}
                    helperText={touched.companyName && errors.companyName}
                  />

                  <TextField
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Address"
                    multiline
                    rows={2}
                    {...getFieldProps('address')}
                  />

                  <LoadingButton
                    id="sign-up-button"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {selectedOwner ? 'Update' : 'Register'}
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Container>
        </div>
      </Dialog>

      <Toaster ref={toastRef} />
    </div>
  );
}
