import { useSelector } from 'react-redux';
import { Stack, Autocomplete, TextField } from '@mui/material';
// utils
import { useShops } from '../../hooks/owner';

export default function ShopSelect({setShop}) {

    const Items = useShops({
        startAfter: undefined,
        limit: 1000,
        filterName: null
    });

    const shopNames = ['All'];
    const currentUser = useSelector((state) => state.firebase.auth);
    Items.data.map(item=> shopNames.push(item.shopName));

    return shopNames.length >0 ?(
        <Stack spacing={10} sx={{ marginBottom: '10px'}}>
            <Autocomplete
                fullWidth
                size="small"
                disablePortal
                options={shopNames}
                defaultValue={'All'}
                renderInput={(params) => <TextField {...params} label="Shop" name="shopName"
                />}
                onChange={(event, value) => {setShop(value)}}
            />
        </Stack>
    ):null;
}
