import { Icon } from '@iconify/react';
import plusOutlined from '@iconify/icons-ant-design/plus-outlined';
// material
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { AddCustomerDialog } from '../../../_adds/owner';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  '&:hover': {
    backgroundColor: theme.palette.primary.lighter,
    border: `2px solid ${theme.palette.primary.lighter}`
  },
  border: `2px solid #e8e8e8`,
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  userSelect: 'none',
  width: '100%'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundColor: theme.palette.primary.lighter
}));

// ----------------------------------------------------------------------

export default function AppAddCustomer() {
  return (
    <AddCustomerDialog>
      <RootStyle>
        <IconWrapperStyle>
          <Icon icon={plusOutlined} width={24} height={24} />
        </IconWrapperStyle>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          Add Customer
        </Typography>
      </RootStyle>
    </AddCustomerDialog>
  );
}
