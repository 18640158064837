import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkForRole } from '../utils/checkAuthUserRole';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// auth
import Login from '../pages/Login';
import Register from '../pages/Register';
import Verification from '../pages/Verification';
import ResetPassword from '../pages/ResetPassword';
import NewPassword from '../pages/NewPassword';
import NotFound from '../pages/Page404';

//-----------------------------------------------------------------------

export default function Router({ isAuthenicated }) {
  const { token } = useSelector((state) => state.firebase.profile);

  return useRoutes([
    // owner routes
    {
      path: '/dashboard',
      element: isAuthenicated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: token && checkForRole(token).routes
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verification', element: <Verification /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
