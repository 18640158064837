import * as React from 'react';
import { Icon } from '@iconify/react';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';

export default function StaffEmpty() {
  const RootStyle = styled(Paper)(() => ({
    height: '60vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }));

  const IconStyle = styled(Icon)(({ theme }) => ({
    color: theme.palette.grey[400]
  }));

  const TypographyStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[500]
  }));

  return (
    <RootStyle>
      <div>
        <IconStyle icon={personAddFill} width={95} height={95} />
        <TypographyStyle variant="subtitle1" sx={{ mt: 1 }}>
          No Staff yet
        </TypographyStyle>
      </div>
    </RootStyle>
  );
}
