import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { isEmpty } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Container,
  Stack,
  Typography,
  Card,
  Grid,
  TextField,
  MenuItem,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TablePagination,
  CircularProgress,
  Box,
  Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  ListHead,
  ListToolbar,
  DeleteDialog,
  StatusDialog
} from '../../../components/_dashboard/super_admin/@general';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import Label from '../../../components/Label';

import { useMeasurementsParent } from '../../../hooks/super_admin';
import {
  addMeasurementsParent,
  removeMeasurementsParent,
  updateMeasurementsParent
} from '../../../redux/slices/super_admin/measurements';
import { EditMeasurementsParentDialog } from '../../../components/_adds/super_admin';

import { condenseText } from '../../../utils/formatText';

const SEX = [
  {
    name: 'male',
    value: 'male'
  },
  {
    name: 'female',
    value: 'female'
  },
  {
    name: 'other',
    value: 'other'
  }
];

const TABLE_HEAD = [
  { id: 'parentTilte', label: 'Parent Title', alignRight: false },
  { id: 'sex', label: 'Sex', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

export default function MeasurementsParent() {
  const { searchedMeasurementsParent } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { measurementsParentCount } = useSelector((state) => state.firebase.profile);

  // ----------------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [cursor, setCursor] = useState(undefined);
  const [isParentEditOpen, setIsParentEditOpen] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [isParentStatusOpen, setIsParentStatusOpen] = useState(false);
  const [isParentDeleteOpen, setIsParentDeleteOpen] = useState(false);
  const [error, setError] = useState(null);
  const measurementsParent = useMeasurementsParent({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  // ----------------------------------------------------------------------

  const dispatch = useDispatch();

  // ----------------------------------------------------------------------

  const MeasurementsParentSchema = Yup.object().shape({
    parentTitle: Yup.string().required('Parent Title is required')
  });

  const [initialValues] = useState({
    parentTitle: '',
    sex: 'other'
  });

  const formik = useFormik({
    initialValues,
    validationSchema: MeasurementsParentSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setError(null);
        const parentInfo = {
          ...values,
          searchMatch: condenseText(values.parentTitle)
        };
        await dispatch(addMeasurementsParent(parentInfo));
        resetForm();
      } catch (error) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // -----------------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(measurementsParent.data[measurementsParent.data.length - 1].createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteParent = (parent) => {
    setSelectedParent(parent);
    setIsParentDeleteOpen(true);
  };

  const handleEditParent = (parent) => {
    setSelectedParent(parent);
    setIsParentEditOpen(true);
  };

  const handleChangeStatus = (parent) => {
    setSelectedParent(parent);
    setIsParentStatusOpen(true);
  };

  // -----------------------------------------------------------------------

  useEffect(() => {
    if (!isParentEditOpen && !isParentDeleteOpen && !isParentStatusOpen) {
      setSelectedParent(null);
    }
  }, [isParentEditOpen, isParentDeleteOpen, isParentStatusOpen]);

  useEffect(() => {
    if (requesting.searchedMeasurementsParent) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedMeasurementsParent]);

  // -----------------------------------------------------------------------

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName
            ? searchedMeasurementsParent?.length
            : measurementsParent.data?.length
        )
      : 0;

  const { errors, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Super Admin: Measurements Parent | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Measurements Parent
          </Typography>
        </Stack>
        {error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}
        <Card sx={{ p: 2.5, mb: 5 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Parent title"
                    {...getFieldProps('parentTitle')}
                    error={Boolean(errors.parentTitle)}
                    helperText={errors.parentTitle}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField fullWidth select label="Sex" {...getFieldProps('sex')}>
                    {SEX.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <LoadingButton
                    sx={{ mt: 0.5 }}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    startIcon={<Icon icon={plusFill} />}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Add
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>

        <Card>
          {!!measurementsParent.data.length && (
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedMeasurementsParent}
            />
          )}
          {measurementsParent.loading || requesting.searchedMeasurementsParent ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!measurementsParent.data.length ? (
                'Is empty'
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={
                          filterName
                            ? searchedMeasurementsParent?.length || 0
                            : measurementsParentCount || 0
                        }
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName
                          ? searchedMeasurementsParent || measurementsParent.data
                          : measurementsParent.data
                        )
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { parentTitle, sex, isActive } = row;

                            return (
                              <TableRow key={row.id} tabIndex={-1}>
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{parentTitle}</TableCell>
                                <TableCell align="left">{sex}</TableCell>
                                <TableCell align="left">
                                  <Label
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleChangeStatus(row)}
                                    variant="ghost"
                                    color={(!isActive && 'error') || 'success'}
                                  >
                                    {isActive ? sentenceCase('active') : sentenceCase('inactive')}
                                  </Label>
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    onClick={() => handleEditParent(row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    variant="contained"
                                    component="div"
                                    color="error"
                                    onClick={() => handleDeleteParent(row)}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedMeasurementsParent) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedMeasurementsParent && !!measurementsParent.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={
                filterName ? searchedMeasurementsParent?.length || 0 : measurementsParentCount || 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {/* ---- Dialogs start ---- */}
        {isParentDeleteOpen && (
          <DeleteDialog
            isOpen={isParentDeleteOpen}
            setIsOpen={setIsParentDeleteOpen}
            selectedItem={selectedParent}
            delFunc={removeMeasurementsParent}
          />
        )}
        {isParentEditOpen && (
          <EditMeasurementsParentDialog
            isOpen={isParentEditOpen}
            setIsOpen={setIsParentEditOpen}
            selectedParent={selectedParent}
          />
        )}
        {isParentStatusOpen && (
          <StatusDialog
            isOpen={isParentStatusOpen}
            setIsOpen={setIsParentStatusOpen}
            selectedItem={selectedParent}
            statusFunc={updateMeasurementsParent}
          />
        )}
        {/* ---- Dialogs end ---- */}
      </Container>
    </Page>
  );
}
