import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const addExpenditure = createAsyncThunk(
  'expenditure/add',
  async ({expenditureInfo, ownerId}, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const timestamp = firestore.FieldValue.serverTimestamp();
    const rest = {
      createdAt: timestamp,
      updatedAt: timestamp
    };

    const ownerDocRef = firestore.collection('users').doc(ownerId);
    const expenditureDocRefId = firestore.collection('expenditures').doc().id;
    const expenditureDocRef = firestore.collection('expenditures').doc(expenditureDocRefId);

    await firestore.runTransaction(async (transaction) => {
      const ownerDoc = await transaction.get(ownerDocRef);

      if (!ownerDoc.exists) {
        throw new Error('Owner does not exist!');
      }

      let newExpendituresCount;
      if (ownerDoc.data().expendituresCount || ownerDoc.data().expendituresCount === 0) {
        newExpendituresCount = ownerDoc.data().expendituresCount + 1;
      } else {
        newExpendituresCount = 1;
      }

      transaction.set(expenditureDocRef, {
        ownerId,
        ...expenditureInfo,
        ...rest
      });

      transaction.update(ownerDocRef, { expendituresCount: newExpendituresCount });
    });
  }
);

// -----------------------------------------------------------------------------------------

export const removeExpenditure = createAsyncThunk(
  'expenditure/delete',
  async (expenditureData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const ownerId = firebase.auth().currentUser.uid;
    const ownerDocRef = firestore.collection('users').doc(ownerId);
    const expenditureDocRef = firestore.collection('expenditures').doc(expenditureData.id);

    await firestore.runTransaction(async (transaction) => {
      const ownerDoc = await transaction.get(ownerDocRef);
      const expenditureDoc = await transaction.get(expenditureDocRef);

      if (!ownerDoc.exists) {
        throw new Error('Owner does not exist!');
      }

      if (!expenditureDoc.exists) {
        throw new Error('Expenditure does not exist!');
      }

      const newExpendituresCount = ownerDoc.data().expendituresCount - 1;
      transaction.delete(expenditureDocRef);

      transaction.update(ownerDocRef, { expendituresCount: newExpendituresCount });
    });
  }
);

// -----------------------------------------------------------------------------------------

export const updateExpenditure = createAsyncThunk(
  'expenditure/update',
  async (expenditureData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const timestamp = firestore.FieldValue.serverTimestamp();

    firestore.update(
      { collection: 'expenditures', doc: expenditureData.id },
      {
        ...expenditureData,
        updatedAt: timestamp
      }
    );
  }
);

const initialState = {
  error: null
};

const expendituresSlice = createSlice({
  name: 'expenditures',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addExpenditure.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addExpenditure.rejected, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // -----------------------------------------------------------------------------------------

    builder.addCase(removeExpenditure.fulfilled, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // -----------------------------------------------------------------------------------------

    builder.addCase(updateExpenditure.fulfilled, (state, action) => {
      state.error = action.error;
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default expendituresSlice.reducer;
