import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
} from '@mui/material';
// utils
import { fDate, fDateTime } from '../../../../utils/formatTime';
import { fCurrency } from '../../../../utils/formatNumber';
// components
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
//
import InvoiceToolbar from './InvoiceToolbar';
import useInvPayments from '../../../../hooks/owner/useInvPayments';

// ----------------------------------------------------------------------

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

InvoiceDetails.propTypes = {
  invoice: PropTypes.object,
};

export default function InvoiceDetails({ invoice }) {
  const theme = useTheme();
  const currentUser = useSelector((state) => state.firebase.profile);

  const invoicePayments = useInvPayments({
    startAfter: undefined,
    limit: 1000,
    filterName: ''
  });

  if (!invoice) {
    return null;
  }


  const {
    id,
    items,
    taxes,
    status,
    dueDate,
    discount,
    invoiceTo,
    createDate,
    totalPrice,
    paidAmount,
  } = invoice;

  if (invoice.length === 0) return null;

  return (
    <>
      <InvoiceToolbar invoice={invoice} invoicePayments={invoicePayments}/>

      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            {/* <Image disabledEffect visibleByDefault alt="logo" src="/static/mainlogo.png" sx={{ maxWidth: 120 }} /> */}
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={
                  (status === 'paid' && 'success') ||
                  (status === 'unpaid' && 'warning') ||
                  (status === 'overdue' && 'error') ||
                  (status === 'partial' && 'info') ||
                  'default'
                }
                sx={{ textTransform: 'uppercase', mb: 1 }}
              >
                {status}
              </Label>
              {/* ( GH₵ {paidAmount} paid) */}
              <Typography variant="h6">{`INV-${id}`}</Typography>
              <Typography variant="h6">Totl amount Paid :₵{paidAmount || 0}</Typography>
              <Typography variant="h6">Totl amount remaining :₵{fCurrency(Math.max(totalPrice-taxes-discount-paidAmount || 0, 0))}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Invoice from
            </Typography>
            <Typography variant="body2">{currentUser.companyName}</Typography>
            <Typography variant="body2">{currentUser.address}</Typography>
            <Typography variant="body2">Phone: {currentUser.phoneNumber}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Invoice to
            </Typography>
            <Typography variant="body2">{invoiceTo.customerData.firstName}</Typography>
            <Typography variant="body2">{invoiceTo.customerData.address}</Typography>
            <Typography variant="body2">Phone: {invoiceTo.customerData.phoneNumber}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              date create
            </Typography>
            <Typography variant="body2">{fDate(createDate.seconds * 1000)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Due date
            </Typography>
            <Typography variant="body2">{fDate(dueDate.seconds * 1000)}</Typography>
          </Grid>
        </Grid>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 960 }}>
            <Table>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>
                  <TableCell align="left">Product</TableCell>
                  <TableCell align="left">ShopName</TableCell>
                  <TableCell align="left">Qty</TableCell>
                  <TableCell align="right">Unit price</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {items.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="left">{row.service}</TableCell>
                    <TableCell align="left">
                      <Box sx={{ maxWidth: 560 }}>
                        <Typography variant="subtitle2">{row.shopName}</Typography>
                        {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                          {row.quantity}
                        </Typography> */}
                      </Box>
                    </TableCell>
                    <TableCell align="left">{row.quantity}</TableCell>
                    <TableCell align="right">GH₵ {fCurrency(row.price)}</TableCell>
                    <TableCell align="right">GH₵ {fCurrency(row.total)}</TableCell>
                  </TableRow>
                ))}

                <RowResultStyle>
                  <TableCell colSpan={4} />
                  <TableCell align="right">
                    <Box sx={{ mt: 2 }} />
                    <Typography>Subtotal</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Box sx={{ mt: 2 }} />
                    <Typography>GH₵ {fCurrency(totalPrice)}</Typography>
                  </TableCell>
                </RowResultStyle>

                <RowResultStyle>
                  <TableCell colSpan={4} />
                  <TableCell align="right">
                    <Typography>Discount</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Typography sx={{ color: 'error.main' }}>GH₵ {discount && fCurrency(-discount||0)}</Typography>
                  </TableCell>
                </RowResultStyle>

                <RowResultStyle>
                  <TableCell colSpan={4} />
                  <TableCell align="right">
                    <Typography>Taxes</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Typography>GH₵ {(taxes||0) && fCurrency(taxes||0)}</Typography>
                  </TableCell>
                </RowResultStyle>

                <RowResultStyle>
                  <TableCell colSpan={4} />
                  <TableCell align="right">
                    <Typography variant="h6">Grand Total</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="h6">GH₵ {fCurrency(totalPrice-taxes-discount)}</Typography>
                  </TableCell>
                </RowResultStyle>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Divider sx={{ mt: 5 }} />

        <Grid container>
          <Grid item xs={12} md={12} sx={{ py: 3 }}>
            <Typography variant="subtitle2">Payment History</Typography>
            <Table>
            <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Method</TableCell>
                  <TableCell align="left">Number</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
            {invoicePayments.data.length>0 ? invoicePayments.data.filter(item=>item.invoiceId === invoice.id).map((item, index)=>(
                <TableBody key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{fDateTime(item.createdAt.seconds * 1000)}</TableCell>
                  <TableCell align="left">{item.paymentMethod.replace("Number", "")}</TableCell>
                  <TableCell align="left">{item.paymentNumber}</TableCell>
                  <TableCell align="right">GH₵ {fCurrency(item.paymentAmount || 0)}</TableCell>
                </TableBody>
              )):null}
            </Table>
          </Grid>

        </Grid>
      </Card>
    </>
  );
}
