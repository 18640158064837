// owner pages
import { Navigate } from 'react-router-dom';
import OwnerDashboard from '../../pages/owner/Dashboard';
import OwnerCustomers from '../../pages/owner/Customers';
import OwnerStaff from '../../pages/owner/Staff';
import OwnerCommunication from '../../pages/owner/Communication';
import OwnerProfile from '../../pages/owner/Profile';
import OwnerSettings from '../../pages/owner/Settings';
import OwnerSewings from '../../pages/owner/Sewings';
import OwnerShop from '../../pages/owner/Shop';
import OwnerInventory from '../../pages/owner/Inventory';
import OwnerInvoiceList from '../../pages/owner/InvoiceList';
import OwnerInvoiceDetails from '../../pages/owner/InvoiceDetails';
import OwnerInvoiceCreate from '../../pages/owner/InvoiceCreate';
import OwnerInvoiceEdit from '../../pages/owner/InvoiceEdit';
import OwnerReport from '../../pages/owner/Report';
import {
  FinanceOverview as OwnerFinanceOverview,
  FinanceReports as OwnerFinanceReports,
  FinanceShopReports as OwnerFinanceShopReports,
  FinanceSewingReports as OwnerFinanceSewingReports,
  FinanceExpenditure as OwnerFinanceExpenditure
} from '../../pages/owner/finances';
import Subscriptions from '../../pages/owner/Subscriptions';
import {
  TransactionsSewings as OwnerTransactionSewings,
  TransactionsSubscriptions as OwnerTransactionSubscriptions
} from '../../pages/owner/transactions';

const ownerRoutes = [
  { element: <Navigate to="/dashboard/app" replace /> },
  { path: 'app', element: <OwnerDashboard /> },
  { path: 'sewings', element: <OwnerSewings /> },
  { path: 'customers', element: <OwnerCustomers /> },
  { path: 'staff', element: <OwnerStaff /> },
  { path: 'communication', element: <OwnerCommunication /> },
  { path: 'profile', element: <OwnerProfile /> },
  { path: 'settings', element: <OwnerSettings /> },
  { path: 'ecommerce/shop', element: <OwnerShop /> },
  { path: 'ecommerce/inventory', element: <OwnerInventory /> },
  { path: 'ecommerce/invoiceList', element: <OwnerInvoiceList /> },
  { path: 'ecommerce/invoiceDetails/:id', element: <OwnerInvoiceDetails /> },
  { path: 'ecommerce/invoiceCreate', element: <OwnerInvoiceCreate /> },
  { path: 'ecommerce/invoiceEdit/:id', element: <OwnerInvoiceEdit /> },
  { path: 'ecommerce/report', element: <OwnerReport /> },
  { path: 'finances/expenditure', element: <OwnerFinanceExpenditure /> },
  { path: 'finances/overview', element: <OwnerFinanceOverview /> },
  { path: 'finances/reports', element: <OwnerFinanceReports /> },
  { path: 'finances/reports/shopReports', element: <OwnerFinanceShopReports /> },
  { path: 'finances/reports/sewingReports', element: <OwnerFinanceSewingReports /> },
  { path: 'subscriptions', element: <Subscriptions /> },
  { path: 'transactions/sewings', element: <OwnerTransactionSewings /> },
  { path: 'transactions/subscriptions', element: <OwnerTransactionSubscriptions /> }
];

export default ownerRoutes;
