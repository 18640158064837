import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Stack } from '@mui/material';
import { FormProvider } from '../../../../components/hook-form';
//
import InvoiceNewEditDetails from './InvoiceNewEditDetails';
import InvoiceCategory from './InvoiceCategory';
import InvoiceNewEditAddress from './InvoiceNewEditAddress';

import InvoiceNewEditStatusDate from './InvoiceNewEditStatusDate';
import InvoiceDialog from './InvoiceDialog';
// ----------------------------------------------------------------------

InvoiceNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentInvoice: PropTypes.object,
  selectedShopName: PropTypes.string,
};

export default function InvoiceNewEditForm({ selectedShopName, isEdit, currentInvoice }) {
  const [Open, setOpen] = useState(false)

  const [loadingSend, setLoadingSend] = useState(false);

  const [category, setCategory] = useState('Product');

  const [newInvoiceDelivered, setNewInvoiceDelivered] = useState()
  const currentUser = useSelector((state) => state.firebase.profile);
  const NewUserSchema = Yup.object().shape({
    // invoiceTo: Yup.mixed().nullable().required('Invoice to is required'),
    createDate: Yup.string().nullable().required('Create date is required'),
    dueDate: Yup.string().nullable().required('Due date is required'),
  });

  const defaultValues = useMemo(
    () => ({
      // id: currentInvoice?.id || null,
      invoiceCategory: currentInvoice?.category || 'Product',
      invoiceNumber: currentInvoice?.invoiceNumber || '17099',
      createDate: currentInvoice? (new Date(currentInvoice.createDate.seconds * 1000)) : null,
      dueDate: currentInvoice? (new Date(currentInvoice.dueDate.seconds * 1000)) : null,
      taxes: currentInvoice?.taxes || '',
      status: currentInvoice?.status || 'unpaid',
      discount: currentInvoice?.discount || '',
      invoiceFrom: currentInvoice?.invoiceFrom || currentUser,
      invoiceTo: currentInvoice?.invoiceTo || null,
      items: currentInvoice?.items || [{ title: '', description: '', service: '', quantity: 0, price: 0, total: 0 }],
      paidAmount: currentInvoice?.paidAmount || 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentInvoice]
  );

  const methods = useForm({
    resolver: yupResolver(category === 'Product' ?  NewUserSchema : NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentInvoice) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentInvoice]);

  const newInvoice = {
    ...values,
    items: values.items.map((item) => ({
      ...item,
      total: item.quantity * item.price,
    })),
  };

  const handleClickOpen = async () => {
    try {
      await setOpen(true);
    }
    catch (error) {
      console.log(error)
    }
  };


  const handleSaveAsDraft = async () => {
    if (newInvoice.invoiceTo === null) {
      checkCustomer();
      return;
    }
    if (selectedShopName === 'All') {
      shopAlert();
      return;
    }
    setLoadingSend(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoadingSend(false);
      // console.log("final test========>" ,newInvoice);
      newInvoice.category = (category === undefined ? 'Service' : category);
      newInvoice.totalPrice = newInvoice.items.reduce((prev,next) => prev + next.total,0);
      newInvoice.taxes = newInvoice.items.reduce((prev,next) => prev + (next.tax||0)*next.quantity,0);
      newInvoice.discount = newInvoice.items.reduce((prev,next) => prev + next.discount||0,0);
      newInvoice.totalProfit = newInvoice.items.reduce((prev,next) => prev + (next.price-next.costPrice) * next.quantity,0);
      newInvoice.gTotalPrice = newInvoice.totalPrice - newInvoice.taxes - newInvoice.discount;
      newInvoice.status = 'draft';
      // const totalPrice = allItems.reduce((prev, current) => prev.total + current.total);
      // newInvoice.totalPrice = totalPrice;
      // console.log(totalPrice);
      // console.log(newInvoice);
      setNewInvoiceDelivered(newInvoice);
      handleClickOpen();
      // reset();
      // navigate(PATH_DASHBOARD.invoice.list);
    } catch (error) {
      console.error(error);
    }
  };

  const checkCustomer = (ev) => {
    // ev.stopPropagation();
    // ev.preventDefault();
    alert("Please Select Customer!");
  }

  const shopAlert = (ev) => {
    // ev.stopPropagation();
    // ev.preventDefault();
    alert("Please Select Shop!");
  }


  const handleCreateAndSend = async () => {
    setLoadingSend(true);
    if (newInvoice.invoiceTo === null) {
      checkCustomer();
      return;
    }
    if (selectedShopName === 'All') {
      shopAlert();
      return;
    }
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoadingSend(false);
      // console.log("final test========>" ,newInvoice);
      newInvoice.category = (category === undefined ? 'Service' : category);
      newInvoice.totalPrice = newInvoice.items.reduce((prev,next) => prev + next.total,0);
      newInvoice.taxes = newInvoice.items.reduce((prev,next) => prev + (next.tax||0)*next.quantity,0);
      newInvoice.discount = newInvoice.items.reduce((prev,next) => prev + next.discount||0,0);
      newInvoice.totalProfit = newInvoice.items.reduce((prev,next) => prev + (next.price-next.costPrice) * next.quantity,0);
      newInvoice.gTotalPrice = newInvoice.totalPrice - newInvoice.taxes - newInvoice.discount;
      // console.log("##############3", newInvoice);
      // const totalPrice = allItems.reduce((prev, current) => prev.total + current.total);
      // newInvoice.totalPrice = totalPrice;
      // console.log(totalPrice);
      // console.log(newInvoice);
      setNewInvoiceDelivered(newInvoice);
      handleClickOpen();
      // reset();
      // navigate(PATH_DASHBOARD.invoice.list);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
//    setError(null);
      setOpen(false);
  // if (setIsOwnerOpen) setIsOwnerOpen(false);
  };

if (category !== "Product") {
  return (
    <FormProvider methods={methods}>
      <Card>
        <InvoiceNewEditAddress />
        <InvoiceCategory setCategory={setCategory}/>
        <InvoiceNewEditStatusDate />
        <InvoiceDialog isEdit={isEdit} Open = {Open} handleClose = {handleClose} newInvoice={newInvoiceDelivered} />
        <InvoiceNewEditDetails selectedShopName={selectedShopName}/>
      </Card>

      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
        <LoadingButton
          color="inherit"
          size="large"
          variant="contained"
          loading={ isSubmitting}
          onClick={handleSubmit(handleSaveAsDraft)}
        >
          Save as Draft
        </LoadingButton>

        <LoadingButton
          size="large"
          variant="contained"
          loading={loadingSend && isSubmitting}
          disabled = {category === undefined}
          onClick={handleSubmit(handleCreateAndSend)}
        >
          {isEdit ? 'Update' : 'Create'} & Send
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
  return (
    <FormProvider methods={methods}>
      <Card>
        <InvoiceNewEditAddress />
        {/* <InvoiceCategory setCategory={setCategory}/> */}
        <InvoiceNewEditStatusDate />
        <InvoiceDialog isEdit={isEdit} Open = {Open} handleClose = {handleClose} newInvoice={newInvoiceDelivered} />
        <InvoiceNewEditDetails selectedShopName={selectedShopName}/>
        {/* <InvoiceNewProduct /> */}
      </Card>

      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
        <LoadingButton
          color="inherit"
          size="large"
          variant="contained"
          loading={ isSubmitting}
          onClick={handleSubmit(handleSaveAsDraft)}
        >
          Save as Draft
        </LoadingButton>

        <LoadingButton
          size="large"
          variant="contained"
          loading={loadingSend && isSubmitting}
          disabled = {category === undefined}
          onClick={
            handleSubmit(handleCreateAndSend)
          }
        >
          {isEdit ? 'Update' : 'Create'} & Send
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
