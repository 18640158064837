import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { imagesUrlArrayGet } from '../../../utils/imagesUrlGenerator';

export const addStock = createAsyncThunk(
  'stocks/add',
  async (stockData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const uid = firebase.auth().currentUser.uid
    // const superAdminId = firebase.auth().currentUser.uid;
    const newStocksRefId = firestore.collection('stocks').doc().id;
    const newStocksHistoryRefId = firestore.collection('stockHistories').doc().id;
    const timestamp = firestore.FieldValue.serverTimestamp();

    if (stockData.fabrics.length) {
      stockData.fabrics = await imagesUrlArrayGet(firebase, newStocksRefId, stockData.fabrics);
    }

    firestore.set(
      { collection: 'stockHistories', doc: newStocksHistoryRefId },
      {
        stockId: newStocksRefId,
        date: timestamp,
        status: 'added',
        quantity: stockData.storeQuantity
      }
    );

    firestore.set(
      { collection: 'stocks', doc: newStocksRefId },
      {
        ...stockData,
        ownerId: uid,
        stockData: {
          ...stockData.stockData,
          createdAt: timestamp,
          updatedAt: timestamp
        },
      }
    );
  }
);


export const changeStockStatus = createAsyncThunk(
  'stocks/changeStatus',
  async (stockData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const stock = await firestore.update({ collection: 'stocks', doc: stockData.id }, stockData);

    return stock;
  }
);

export const updateStock = createAsyncThunk(
  'stocks/update',
  async (stockData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const { id } = stockData;
    // const { fabrics, styles } = stockData.stockData;
    const timestamp = firestore.FieldValue.serverTimestamp();
    const newStocksHistoryRefId = firestore.collection('stockHistories').doc().id;

    // if (fabrics && fabrics.length) {
    //   stockData.fabrics = await imagesUrlArrayGet(firebase, id, fabrics);
    // }

    // if (styles && styles.length) {
    //   stockData.styles = await imagesUrlArrayGet(firebase, id, styles);
    // }

    firestore.set(
      { collection: 'stockHistories', doc: newStocksHistoryRefId },
      {
        stockId: id,
        date: timestamp,
        status: 'updated',
        quantity: stockData.storeQuantity
      }
    );

    const stock = await firestore.update(
      { collection: 'stocks', doc: id },
      {
        ...stockData,
        stockData: {
          ...stockData.stockData,
          updatedAt: timestamp
        },
      }
    );
    return stock;
  }
);

export const removeStock = createAsyncThunk(
  'stocks/remove',
  async (stockData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();
    firestore.delete({ collection: 'stocks', doc: stockData.id });
    // firestore.collection("stocks")
    // .get()
    // .then(res => {
    //   res.forEach(element => {
    //     element.ref.delete();
    //   });
    // });
  }
);

export const saleStock = createAsyncThunk(
  'stocks/sale',
  async (stockData, { extra: { getFirestore} }) => {
    const firestore = getFirestore();
    const { id, quantity } = stockData;
    firestore.collection('stocks')
      .doc(id)
      .get()
      .then(doc => {
        const originalQuantity = doc.data().storeQuantity;

        const stock = firestore.update(
          { collection: 'stocks', doc: id },
          {
            storeQuantity: originalQuantity - quantity,
          }
        );
        return stock;
      });

  }
);


const initialState = {
  error: null
};

const stocksSlice = createSlice({
  name: 'stocks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addStock.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addStock.rejected, (state, action) => {
      state.error = action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(changeStockStatus.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(changeStockStatus.rejected, (state, action) => {
      state.error = action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(updateStock.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(updateStock.rejected, (state, action) => {
      state.error = action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(removeStock.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(removeStock.rejected, (state, action) => {
      state.error = action.error;
    });

    builder.addCase(saleStock.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(saleStock.rejected, (state, action) => {
      state.error = action.error;
    });


  }
});

export default stocksSlice.reducer;
