import { useState, useEffect } from 'react';
import { useParams }  from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// _mock_
// import { _invoices } from '../../_mock';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import Invoice from '../../sections/@dashboard/invoice/details';
import { useInvoices } from '../../hooks/owner';

// ----------------------------------------------------------------------

export default function InvoiceDetails() {
  const { themeStretch } = useSettings();

  const { id } = useParams();

  const invoiceData = useInvoices({
    startAfter: undefined,
    limit: 1000,
    filterName: null
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (tableData.length === 0) setTableData(invoiceData.data);
  }, [tableData]);

  // const currentInvoice = _invoices.find((invoice) => invoice.id === id);
  const currentInvoice = tableData.find((invoice) => invoice.id === id);

  // console.log("filterd------------->", currentInvoices);

  // console.log('tabledata===========>', tableData);

  // console.log(currentInvoices);


  // const todoItems = currentInvoices.map((currentInvoice) =>
  //   <Invoice invoice={currentInvoice} />
  // );

  // if (currentInvoices.length === 0 ) return null;

  // const sumDiscount = currentInvoices.reduce((total, currentValue) => total += currentValue.discount,0);
  // const sumTax = currentInvoices.reduce((total, currentValue) => total += currentValue.taxes,0);
  // const subTotal = currentInvoices.reduce((total, currentValue) => total += currentValue.items[0].total,0);

  // console.log(currentInvoices);

  // const allitems = currentInvoices.map(item=>{
  //   return item.items[0];
  // });


  // const todoItems = {
  //   invoiceFrom: currentInvoices[0].invoiceFrom,
  //   invoiceTo : currentInvoices[0].invoiceTo,
  //   createDate: currentInvoices[0].createDate,
  //   dueDate: currentInvoices[0].dueDate,
  //   items: allitems,
  //   discount: sumDiscount,
  //   taxes: sumTax,
  //   subTotalPrice: subTotal,
  // }

    return (
        (tableData.length > 0)? (
          <Page title="Invoice: View">
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading="Invoice Details"
              links={[
                { name: 'Dashboard', href: PATH_DASHBOARD.root },
                {
                  name: 'Invoices',
                  href: PATH_DASHBOARD.invoice.root,
                },
                { name: `INV-${id}` || '' },
              ]}
            />
          <Invoice invoice={currentInvoice} />
          {/* {todoItems} */}
          </Container>
        </Page>
        )
        : null
  );
  // if (tableData.length > 0) return (
  //   <Page title="Invoice: View">
  //     <Container maxWidth={themeStretch ? false : 'lg'}>
  //       <HeaderBreadcrumbs
  //         heading="Invoice Details"
  //         links={[
  //           { name: 'Dashboard', href: PATH_DASHBOARD.root },
  //           {
  //             name: 'Invoices',
  //             href: PATH_DASHBOARD.invoice.root,
  //           },
  //           { name: `INV-${invoice?.invoiceNumber}` || '' },
  //         ]}
  //       />

  //       <Invoice invoice={invoice} />
  //     </Container>
  //   </Page>
  // );
}
