import { useState, useEffect } from 'react';
import { isEmpty } from 'react-redux-firebase';
import { sentenceCase } from 'change-case';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress
} from '@mui/material';
import {
  OwnersListToolbar,
  OwnersEmpty,
  OwnersListHead,
  OwnersViewDialog,
  OwnersStatusDialog,
  OwnersDeleteDialog,
  OwnersPwdResetDialog
} from '../../components/_dashboard/super_admin/owners';
import { AddOwnersDialog } from '../../components/_adds/super_admin';
// components
import Label from '../../components/Label';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';

import { useOwners } from '../../hooks/super_admin';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'company_name', label: 'Company Name', alignRight: false },
  { id: 'owner_name', label: 'Owner Name', alignRight: false },
  { id: 'contact', label: 'Contact', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function Owners() {
  const { searchedOwners } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { ownersCount } = useSelector((state) => state.firebase.profile);

  // ------------------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [cursor, setCursor] = useState(undefined);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [isOwnerEditOpen, setIsOwnerEditOpen] = useState(false);
  const [isOwnerStatusOpen, setIsOwnerStatusOpen] = useState(false);
  const [isOwnerPwdResetOpen, setIsOwnerPwdResetOpen] = useState(false);
  const [ownerOpen, setOwnerOpen] = useState(false);
  const [isOwnerDeleteOpen, setIsOwnerDeleteOpen] = useState(false);
  const owners = useOwners({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(owners.data[owners.data.length - 1].createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleOwnerClick = (owner) => {
    setSelectedOwner(owner);
    setOwnerOpen(true);
  };

  const handleChangeStatus = (e, owner) => {
    e.stopPropagation();
    setSelectedOwner(owner);
    setIsOwnerStatusOpen(true);
  };

  const handleEditClick = (e, owner) => {
    e.stopPropagation();
    setSelectedOwner(owner);
    setIsOwnerEditOpen(true);
  };

  const handlePwdReset = (owner) => {
    setIsOwnerPwdResetOpen(true);
    setSelectedOwner(owner);
  };

  // const handleDeleteClick = (e, owner) => {
  //   e.stopPropagation();
  //   setSelectedOwner(owner);
  //   setIsOwnerDeleteOpen(true);
  // };

  // ------------------------------------------------------------------------

  useEffect(() => {
    if (
      !isOwnerEditOpen &&
      !isOwnerDeleteOpen &&
      !ownerOpen &&
      !isOwnerStatusOpen &&
      !isOwnerPwdResetOpen
    ) {
      setSelectedOwner(null);
    }
  }, [isOwnerEditOpen, isOwnerDeleteOpen, ownerOpen, isOwnerStatusOpen, isOwnerPwdResetOpen]);

  useEffect(() => {
    if (requesting.searchedOwners) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedOwners]);

  // --------------------------------------------------------------------------------

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName ? searchedOwners?.length : owners.data?.length
        )
      : 0;

  return (
    <Page title="Super Admin: Owners | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Owners
          </Typography>
          <AddOwnersDialog
            isOwnerOpen={isOwnerEditOpen}
            setIsOwnerOpen={setIsOwnerEditOpen}
            selectedOwner={selectedOwner}
          >
            <Button variant="contained" component="div" startIcon={<Icon icon={plusFill} />}>
              Add Owner
            </Button>
          </AddOwnersDialog>
        </Stack>

        <Card>
          {!!owners.data.length && (
            <OwnersListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedOwners}
            />
          )}

          {owners.loading || requesting.searchedOwners ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!owners.data.length ? (
                <OwnersEmpty />
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <OwnersListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={filterName ? searchedOwners?.length || 0 : ownersCount || 0}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName ? searchedOwners || owners.data : owners.data)
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { companyName, isEnabled, phoneNumber, firstName, lastName, address } =
                              row;

                            return (
                              <TableRow
                                key={row.id}
                                sx={{ cursor: 'pointer' }}
                                tabIndex={-1}
                                onClick={() => handleOwnerClick(row)}
                              >
                                <TableCell>
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{`${companyName || '-'}`}</TableCell>
                                <TableCell align="left">{`${firstName} ${lastName}`}</TableCell>
                                <TableCell align="left">{phoneNumber || 'N/A'}</TableCell>
                                <TableCell align="left">{address || 'N/A'}</TableCell>
                                <TableCell align="left">
                                  <Label
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(e) => handleChangeStatus(e, row)}
                                    variant="ghost"
                                    color={(!isEnabled && 'error') || 'success'}
                                  >
                                    {isEnabled ? sentenceCase('enabled') : sentenceCase('disabled')}
                                  </Label>
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    onClick={(e) => handleEditClick(e, row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    Edit
                                  </Button>
                                  {/* <Button
                                    variant="contained"
                                    component="div"
                                    color="error"
                                    onClick={(e) => handleDeleteClick(e, row)}
                                  >
                                    Delete
                                  </Button> */}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedOwners) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}

          {!requesting.searchedOwners && !!owners.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filterName ? searchedOwners?.length || 0 : ownersCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {/* ----- Dialogs start ----- */}
        {ownerOpen && (
          <OwnersViewDialog
            isOpen={ownerOpen}
            setIsOpen={setOwnerOpen}
            owner={selectedOwner}
            handlePwdReset={() => handlePwdReset(selectedOwner)}
          />
        )}
        {isOwnerStatusOpen && (
          <OwnersStatusDialog
            isOpen={isOwnerStatusOpen}
            setIsOpen={setIsOwnerStatusOpen}
            selectedOwner={selectedOwner}
          />
        )}
        {isOwnerDeleteOpen && (
          <OwnersDeleteDialog
            isOpen={isOwnerDeleteOpen}
            setIsOpen={setIsOwnerDeleteOpen}
            selectedOwner={selectedOwner}
          />
        )}
        {isOwnerPwdResetOpen && (
          <OwnersPwdResetDialog
            isOpen={isOwnerPwdResetOpen}
            setIsOpen={setIsOwnerPwdResetOpen}
            selectedOwner={selectedOwner}
          />
        )}
        {/* ----- Dialogs end ------ */}
      </Container>
    </Page>
  );
}
