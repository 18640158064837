import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../../utils/formatNumber';
// components
import { BaseOptionChart } from '../../../../components/chart';

// ----------------------------------------------------------------------

AnalyticsSewingCustomers.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartDataAmount: PropTypes.array.isRequired,
  chartDataCount: PropTypes.array.isRequired,
};

export default function AnalyticsSewingCustomers({ title, subheader, chartDataAmount, chartDataCount, ...other }) {
  const chartLabels = chartDataAmount.map((i) => i.label);

  const chartSeries1 = chartDataAmount.map((i) => i.value);
  const chartSeries2 = chartDataCount.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '28%', borderRadius: 2},
    },
    xaxis: {
      categories: chartLabels,
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={[{ name: 'sewed Count', data: chartSeries1, color: 'rgb(255, 193, 7)'}, 
        ]} options={chartOptions} height={414} />
      </Box>
    </Card>
  );
}

// { name: 'purchased count', data: chartSeries2, color: 'rgb(24, 144, 255)'}