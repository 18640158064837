import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import { useRef } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography, Paper, CardHeader, Button } from '@mui/material';
// components
import { CarouselArrows } from '../../../carousel';
//
import { parseMonthCount } from '../../../../utils/formatTime';
import { addSubcriptionTransaction } from '../../../../redux/slices/owner/subscriptions';

// ----------------------------------------------------------------------

export default function SubscriptionsPlans() {
  const theme = useTheme();
  const carouselRef = useRef(null);

  const { subscriptionsPlans } = useSelector((state) => state.firestore.ordered);

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const queriesArr = [
    {
      collection: 'subscriptionsCategories',
      where: [['isActive', '==', true]]
    },
    {
      collection: 'subscriptionsPlans',
      where: [['isActive', '==', true]],
      orderBy: ['createdAt', 'asc']
    }
  ];

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  useFirestoreConnect(queriesArr);

  return (
    <Box sx={{ py: 2 }}>
      <CardHeader
        title="Subscription Plans"
        subheader="Choose your plan"
        action={
          <CarouselArrows
            customIcon="ic:round-keyboard-arrow-right"
            onNext={handleNext}
            onPrevious={handlePrevious}
            sx={{ '& .arrow': { width: 28, height: 28, p: 0 } }}
          />
        }
        sx={{
          p: 0,
          mb: 3,
          '& .MuiCardHeader-action': { alignSelf: 'center' }
        }}
      />

      <Slider ref={carouselRef} {...settings}>
        {(subscriptionsPlans || []).map((item) => (
          <PlanItem key={item.id} item={item} />
        ))}
      </Slider>
    </Box>
  );
}

// ----------------------------------------------------------------------

PlanItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    id: PropTypes.string
  }).isRequired
};

function PlanItem({ item }) {
  const { name, price, plans, id } = item;

  const dispatch = useDispatch();

  const { subscriptionsCategories } = useSelector((state) => state.firestore.ordered);
  const { uid } = useSelector((state) => state.firebase.auth);

  const getCategory = (identifier) => {
    const category = subscriptionsCategories.find((category) => category.identifier === identifier);
    return category?.name;
  };

  const payWithPaystack = () => {
    // eslint-disable-next-line no-undef
    const handler = PaystackPop.setup({
      key: 'pk_test_075fa03fa8203cb13981964c512ffd8893676745',
      email: 'support@fashionspal.com',
      amount: price * 100,
      currency: 'GHS',
      callback(response) {
        // eslint-disable-next-line camelcase
        const { reference } = response;
        const transactionData = {
          id: reference,
          amount: price,
          reference,
          status: 'pending',
          ownerId: uid,
          planId: id,
          type: 'plan'
        };

        dispatch(addSubcriptionTransaction(transactionData));
      },
      onClose() {
        // alert('Transaction was not completed, window closed.');
      }
    });
    handler.openIframe();
  };

  return (
    <Paper
      sx={{ mx: 1.5, borderRadius: 2, border: '1px solid', borderColor: 'background.neutral' }}
    >
      <Stack spacing={2.5} sx={{ p: 3, pb: 2.5 }}>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
            {name || 'N/A'}
          </Typography>
          <Typography variant="h6" sx={{ mt: 0.5, display: 'block' }}>
            {price || price === 0 ? `₵${price}` : 'N/A'}
          </Typography>
        </Stack>

        {plans?.length &&
          plans.map(({ category, quantity, duration }) => (
            <Stack key={category} direction="row" spacing={1}>
              <Icon
                icon={checkmarkFill}
                color="#54D62C"
                width="18px"
                height="18px"
                style={{ overflow: 'inherit' }}
              />
              {quantity ? (
                <Typography variant="body2" component="p">
                  {quantity} {getCategory(category)}
                </Typography>
              ) : (
                <Typography variant="body2" component="p">
                  {getCategory(category)} access for {parseMonthCount(duration)}
                </Typography>
              )}
            </Stack>
          ))}

        {!(id === '6jNQHnS1qP6ZhpxA8sUE') && (
          <Button variant="contained" color="primary" onClick={payWithPaystack}>
            Buy Now
          </Button>
        )}
      </Stack>
    </Paper>
  );
}
