import React, { useState,  useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Radio,
  RadioGroup,
  Dialog,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { addInvoice, updateInvoice, restoreStocks } from '../../../../redux/slices/owner/invoices';
import { saleStock } from '../../../../redux/slices/owner/stocks';
import Toaster from '../../../../components/Toaster';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { checkForRole } from '../../../../utils/checkAuthUserRole';

// ----------------------------------------------------------------------
InvoiceDialog.propTypes = {
  isEdit: PropTypes.bool,
  newInvoice: PropTypes.object,
  Open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function InvoiceDialog({isEdit, Open, handleClose,newInvoice}) {
  const { token, ownerId } = useSelector((state) => state.firebase.profile);
  const currUserId = useSelector((state) => state.firebase.auth.uid);

  const { identifier } = token && checkForRole(token);

  const uid = identifier === 'staff' ? ownerId : currUserId;

  const [selected, setSelected] = useState(undefined);
  const toastRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const onSubmit = async () => {
    newInvoice.invOption = selected;
    if (selected !== 'Invoice') return;
    handleClose();
    if (!isEdit) {
      await dispatch(addInvoice({invoiceData: newInvoice, ownerId: uid})).then(() => {
        navigate(PATH_DASHBOARD.invoice.list);
      });
    }
    else {
      newInvoice.id = id;
      navigate(PATH_DASHBOARD.invoice.list);
      await dispatch(restoreStocks(newInvoice));
      await dispatch(updateInvoice(newInvoice));
    }

  };

  const handleChange = ev => {
    setSelected(ev.target.value);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="xs" open={Open} onClose = {handleClose}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2.5, px: 3 }}>
          <Typography variant="h6"> Invoice Choice </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2.5, px: 3 }}>
          <Box>
              <FormControl name="invoice-choose">
                  <RadioGroup onChange={handleChange} value={selected}>
                      <FormControlLabel value="Invoice" control={<Radio />} label="Invoice" />
                      <FormControlLabel value="Porfoma" control={<Radio />} label="Porfoma" />
                      <FormControlLabel value="Quote" control={<Radio />} label="Quote" />
                  </RadioGroup>
              </FormControl>
          </Box>
          <LoadingButton
            size="large"
            variant="contained"
            onClick={onSubmit}
            disabled = {selected !== 'Invoice'}
          >
            Accept
          </LoadingButton>
        </Stack>
      </Dialog>
      <Toaster ref={toastRef} />
    </div>
  );
}
