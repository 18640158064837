import * as Yup from 'yup';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Proptypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  Alert,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  TextField,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  FormHelperText
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
//
import { condenseText } from '../../../utils/formatText';
import { useSubscriptionsCategories } from '../../../hooks/super_admin';
//
import { addIndividual, updateIndividual } from '../../../redux/slices/super_admin/subscriptions';

EditMeasurementsListDialog.propTypes = {
  isOpen: Proptypes.bool,
  setIsOpen: Proptypes.func,
  selectedItem: Proptypes.object,
  children: Proptypes.node
};

const ChildrenButtonStyle = styled(Button)(() => ({
  width: '100%',
  padding: 0
}));

// ----------------------------------------------------------------------

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ----------------------------------------------------------------------

export default function EditMeasurementsListDialog({ isOpen, setIsOpen, selectedItem, children }) {
  const [open, setOpen] = React.useState(true);
  const [error, setError] = React.useState(null);
  const categories = useSubscriptionsCategories();

  // ---------------------------------------------------------------------------

  const _isMounted = React.useRef(true);
  const dispatch = useDispatch();

  // ----------------------------------------------------------------------

  const MeasurementsListSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    category: Yup.string().required('Category is required'),
    price: Yup.string().required('Price is required'),
    quantity: Yup.string().required('Quantity is required')
  });

  const [initialValues] = React.useState({
    category: '',
    name: '',
    price: '',
    quantity: ''
  });

  const formik = useFormik({
    initialValues,
    validationSchema: MeasurementsListSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const listInfo = {
          ...values,
          searchMatch: condenseText(values.name)
        };

        if (selectedItem) {
          dispatch(updateIndividual(listInfo));
        } else {
          dispatch(addIndividual(listInfo));
        }

        resetForm();
        handleClose();
      } catch (error) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // ----------------------------------------------------------------------

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  // ----------------------------------------------------------------------

  React.useEffect(() => {
    if (isOpen) {
      handleOpen();
      if (selectedItem) {
        formik.setValues(selectedItem);
      }
    } else {
      formik.resetForm();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  React.useEffect(
    () => () => {
      _isMounted.current = false;
    },
    []
  );

  // ----------------------------------------------------------------------

  const { errors, isSubmitting, handleSubmit, getFieldProps, touched } = formik;

  return (
    <div>
      <ChildrenButtonStyle onClick={() => setOpen(true)}>{children}</ChildrenButtonStyle>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {error && <Alert severity="error">{error}</Alert>}
        <DialogTitle>{!selectedItem ? 'Add Top-up' : `Edit ${selectedItem.name}`}</DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Box sx={{ pt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={Boolean(errors.category)}>
                      <InputLabel id="demo-simple-select-label">Category</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        {...getFieldProps('category')}
                        label="Category"
                      >
                        {categories.data.map((item) => (
                          <MenuItem key={item.id} value={item.identifier}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{errors.category}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      label="Price"
                      type="number"
                      {...getFieldProps('price')}
                      error={Boolean(touched.price && errors.price)}
                      helperText={touched.price && errors.price}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₵</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      label="Quantity"
                      type="number"
                      {...getFieldProps('quantity')}
                      error={Boolean(touched.quantity && errors.quantity)}
                      helperText={touched.quantity && errors.quantity}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleSubmit} loading={isSubmitting}>
            {selectedItem ? 'Update' : 'Save'}
          </LoadingButton>
          <Button disabled={isSubmitting} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
