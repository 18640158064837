import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { imagesUrlArrayGet } from '../../../utils/imagesUrlGenerator';

export const updateAccount = createAsyncThunk(
  'account/update',
  async ({ accountInfo, id }, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    if (accountInfo.profileImage) {
      accountInfo.profileImage = await imagesUrlArrayGet(
        'profile',
        firebase,
        id,
        accountInfo.profileImage
      );
    }

    await firestore.update(
      { collection: 'users', doc: id },
      {
        ...accountInfo,
        updatedAt: firestore.FieldValue.serverTimestamp()
      },
      { merge: true }
    );
  }
);

const initialState = {
  error: null
};

const accountSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateAccount.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default accountSlice.reducer;
