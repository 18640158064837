import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
// @mui
import { Grid, Container, Stack } from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';

import {
  // BankingWidgetSummary,
  // BankingBalanceStatistics
  SewingBalanceStatistics,
  SewingCustomerStatistics,
  SewingStaffStatistics,
  // BankingExpensesCategories
} from '../../../components/_dashboard/owner/finances';

const categories = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

function FinanceSewingReports() {
  const { themeStretch } = useSettings();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().toLocaleString('default', { month: 'short' });

  // ----------------------------------------------------------------------

  const ownerId = useSelector((state) => state.firebase.auth.uid);
  const { finances } = useSelector((state) => state.firestore.ordered);

  // ----------------------------------------------------------------------

  const [currentMonthIndex, setCurrentMonthIndex] = useState(null);
  const [query, setQuery] = useState({
    collection: 'finances',
    where: [
      ['ownerId', '==', ownerId],
      ['year', '==', currentYear]
    ]
  });
  const [chartData, setChartData] = useState([]);

  // ----------------------------------------------------------------------

  useEffect(() => {
    const data = [
      { name: 'Invoice', data: [] },
      { name: 'Income', data: [] },
      { name: 'Expenditure', data: [] }
    ];

    categories.forEach((category) => {
      if (finances && finances.length > 0) {
        const currFinance = finances[0].data[category];
        if (currFinance) {
          data[0].data.push(currFinance.invoice);
          data[1].data.push(currFinance.income);
          data[2].data.push(currFinance.expenditure);
        } else {
          data[0].data.push(null);
          data[1].data.push(null);
          data[2].data.push(null);
        }
      } else {
        data[0].data.push(null);
        data[1].data.push(null);
        data[2].data.push(null);
      }
    });

    setCurrentMonthIndex(categories.indexOf(currentMonth));

    setChartData(data);
  }, [currentMonth, finances, query]);

  useEffect(() => {
    setQuery({
      collection: 'finances',
      where: [
        ['ownerId', '==', ownerId],
        ['year', '==', currentYear]
      ]
    });
  }, [currentYear, ownerId]);

  // ----------------------------------------------------------------------

  useFirestoreConnect(query);

  return (
    <Page title="Overview | FashionsPal">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <BankingWidgetSummary
                title="Invoice"
                icon="eva:diagonal-arrow-left-down-fill"
                percent={
                  ((parseFloat(chartData[0]?.data[currentMonthIndex]) -
                    parseFloat(chartData[0]?.data[currentMonthIndex - 1])) /
                    (parseFloat(chartData[0]?.data[currentMonthIndex]) +
                      parseFloat(chartData[0]?.data[currentMonthIndex - 1]))) *
                  100
                }
                total={chartData[0]?.data[currentMonthIndex] || 0}
                chartData={chartData[0]?.data || []}
              />
              <BankingWidgetSummary
                title="Income"
                color="info"
                icon="eva:diagonal-arrow-right-up-fill"
                percent={
                  ((parseFloat(chartData[1]?.data[currentMonthIndex]) -
                    parseFloat(chartData[1]?.data[currentMonthIndex - 1])) /
                    (parseFloat(chartData[1]?.data[currentMonthIndex]) +
                      parseFloat(chartData[1]?.data[currentMonthIndex - 1]))) *
                  100
                }
                total={chartData[1]?.data[currentMonthIndex] || 0}
                chartData={chartData[1]?.data || []}
              />
              <BankingWidgetSummary
                title="Expenses"
                color="warning"
                icon="eva:diagonal-arrow-left-down-fill"
                percent={
                  ((parseFloat(chartData[2]?.data[currentMonthIndex]) -
                    parseFloat(chartData[2]?.data[currentMonthIndex - 1])) /
                    (parseFloat(chartData[2]?.data[currentMonthIndex]) +
                      parseFloat(chartData[2]?.data[currentMonthIndex - 1]))) *
                  100
                }
                total={chartData[2]?.data[currentMonthIndex] || 0}
                chartData={chartData[2]?.data || []}
              />
            </Stack>
          </Grid> */}

          <Grid item xs={12}>
            <Stack spacing={3}>
              <SewingBalanceStatistics />
              {/* <PurchasingCustomerStatistics /> */}
              <SewingCustomerStatistics />
              <SewingStaffStatistics />
              {/* <SalesProductStatistics /> */}
              {/* <SalesShopStatistics /> */}

              {/* <BankingExpensesCategories /> */}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default FinanceSewingReports;
