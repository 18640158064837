import React, { useState, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import closeFill from '@iconify/icons-eva/close-fill';
import { /* alpha, */ styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Container,
  Alert,
  TextField,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  MenuItem,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Toaster from '../../Toaster';
import { useCategories } from '../../../hooks/owner';
import { addCategory, removeCategory} from '../../../redux/slices/owner/categories';
import Iconify from '../../Iconify';
import { checkForRole } from '../../../utils/checkAuthUserRole';

AddCategoryDialog.propTypes = {
  children: PropTypes.node,
  setIsOwnerOpen: PropTypes.func
};

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ----------------------------------------------------------------------

const ChildrenButtonStyle = styled(Button)(() => ({
  width: '100%',
  padding: 0
}));

// ----------------------------------------------------------------------

export default function AddCategoryDialog({ children, setIsOwnerOpen }) {

  const dispatch = useDispatch();
  const categories = useCategories({
    startAfter: undefined,
    limit: 50,
//    filterName
  });

  const { token, ownerId } = useSelector((state) => state.firebase.profile);
  const currUserId = useSelector((state) => state.firebase.auth.uid);

  const { identifier } = token && checkForRole(token);

  const uid = identifier === 'staff' ? ownerId : currUserId;

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const stockContainerRef = useRef(null);
  const toastRef = useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError(null);
    setOpen(false);
    if (setIsOwnerOpen) setIsOwnerOpen(false);
  };

  const onDeleteRow = (id) => {
    dispatch(removeCategory(id));
    handleClose();
  }

  const onSubmit = () => {
    if (document.getElementById('addItem').value.length === 0) return;
    const info = {
      name: document.getElementById('addItem').value,
      // owner: currentUser.phoneNumber,
      ownerId: uid,
    }
    dispatch(addCategory(info));
    handleClose();
  }

  // const cats = ['main category', 'sub category'];

  return (
    <div>
      <ChildrenButtonStyle onClick={handleClickOpen}>{children}</ChildrenButtonStyle>
      <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
        <div ref={stockContainerRef} style={{ overflowY: 'auto' }}>
          <AppBar sx={{ position: 'sticky' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Icon icon={closeFill} width={24} height={24} />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Add or Remove Category
              </Typography>
            </Toolbar>
          </AppBar>
          <Container maxWidth="sm" sx={{ pb: 5 }}>
            {error && (
              <Alert sx={{ mt: -1.5, mb: 3 }} severity="error">
                {error}
              </Alert>
            )}

            <Container sx={{ p: 3 }}>
            <TableContainer sx={{ mb: 3 }}>
            <Table>
              <TableBody>
              {categories.data.length > 0 ? categories.data.map((item, index)=> (
                  <TableRow key={index} sx={{ borderBottomWidth: 1, border: 'solid' }}>
                    <TableCell sx={{ alignItems: 'center' }}>{index + 1}</TableCell>
                    <TableCell sx={{ alignItems: 'center' }}>{item.name}</TableCell>
                    <TableCell sx={{ alignItems: 'center' }}>
                      <MenuItem
                        key={ item.id }
                        onClick={() => {
                        onDeleteRow(item.id);
                      }}
                      sx={{ color: 'error.main' }}
                      >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>

                    </TableCell>
                  </TableRow>
                )):null}
              </TableBody>
            </Table>
            </TableContainer>

              <TextField id="addItem" label="Add New Category" variant="outlined" size="large" fullWidth/>
              <LoadingButton
                id="sign-up-button"
                fullWidth
                size="large"
                variant="contained"
                sx={{mt: 3}}
                onClick = {onSubmit}
              >
                Add

              </LoadingButton>
            </Container>
          </Container>
        </div>
      </Dialog>
      <Toaster ref={toastRef} />
    </div>
  );
}
