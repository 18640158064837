import faker from 'faker';
import { sample } from 'lodash';

const customers = [...Array(24)].map(() => ({
  id: faker.datatype.uuid(),
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  email: faker.internet.email(),
  phoneNumber: faker.phone.phoneNumber(),
  address: faker.address.streetAddress(),
  city: faker.address.city(),
  country: faker.address.country(),
  birthday: faker.date.past().toISOString(),
  sex: sample(['male', 'female'])
}));

export default customers;
