import * as React from 'react';
import Proptypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  InputAdornment,
  TextField,
  Grid,
  Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { fCurrency } from '../../../../utils/formatNumber';

import { changeSewingStatus } from '../../../../redux/slices/owner/sewings';

SewStatusDialog.propTypes = {
  isOpen: Proptypes.bool,
  setIsOpen: Proptypes.func,
  selectedSew: Proptypes.object
};

export default function SewStatusDialog({ isOpen, setIsOpen, selectedSew }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [showForm, setShowForm] = React.useState(false);
  const [amountError, setAmountError] = React.useState(null);

  // ----------------------------------------------------------------------

  const dispatch = useDispatch();
  const _isMounted = React.useRef(true);

  // ----------------------------------------------------------------------

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSave = async () => {
    if (selectedSew) {
      if (amountError) return;
      try {
        const sewingInfo = {
          ...selectedSew,
          sewingData: {
            ...selectedSew.sewingData,
            amountPaid: Number(selectedSew.sewingData.amountPaid) + Number(value)
          }
        };
        setIsLoading(true);
        await dispatch(changeSewingStatus(sewingInfo));
        handleClose();
      } catch (error) {
        setError(error.message);
      } finally {
        if (_isMounted.current) {
          setIsLoading(false);
        }
      }
    }
  };

  // ----------------------------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  React.useEffect(() => {
    setAmountError(null);
    if (
      Number(selectedSew.sewingData.amountPaid) + Number(value) >
      Number(selectedSew.sewingData.cost)
    ) {
      setAmountError('Cannot exceed remaining amount');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(
    () => () => {
      _isMounted.current = false;
    },
    []
  );

  // ----------------------------------------------------------------------

  return (
    <>
      <Dialog maxWidth="150px" open={open}>
        {error && (
          <Alert sx={{ maxWidth: '278px' }} severity="error">
            {error}
          </Alert>
        )}
        <DialogTitle sx={{ textAlign: 'center' }}>Add Payment</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              mt: 3,
              width: 'fit-content',
              maxWidth: '230px'
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1">Cost of Sewing:</Typography>
                <Typography variant="subtitle1">₵{selectedSew.sewingData.cost}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1">Amount Paid:</Typography>
                <Typography variant="subtitle1">
                  ₵{fCurrency(Number(selectedSew.sewingData.amountPaid) + Number(value))}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1">Amount Remaining:</Typography>
                <Typography variant="subtitle1">
                  ₵
                  {fCurrency(
                    Number(selectedSew.sewingData.cost) -
                      Number(selectedSew.sewingData.amountPaid) -
                      Number(value)
                  )}
                </Typography>
              </Grid>
              {showForm && (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1.5 }}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Enter Amount"
                    value={value}
                    onChange={handleChange}
                    helperText={amountError}
                    error={!!amountError}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₵</InputAdornment>
                    }}
                  />
                </Grid>
              )}
              {!showForm && (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={() => setShowForm(true)} startIcon={<Icon icon={plusFill} />}>
                    Add Payment
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton loading={isLoading} onClick={handleSave}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
