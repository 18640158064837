import * as Yup from 'yup';
import { useState, forwardRef, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import closeFill from '@iconify/icons-eva/close-fill';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Alert,
  Container,
  TextField,
  Grid,
  MenuItem,
  FormControl,
  FormLabel,
  FormGroup,
  Switch,
  InputAdornment,
  CircularProgress,
  FormControlLabel
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Toaster from '../../Toaster';

import { addStaff, updateStaff } from '../../../redux/slices/owner/staff';

import { stringfyPhoneNumber } from '../../../utils/formatPhoneNumber';
import { condenseName } from '../../../utils/formatText';
import { getErrorMessage } from '../../../utils/firebaseError';
import { fDate } from '../../../utils/formatTime';

const SEX = [
  {
    name: 'male',
    value: 'male'
  },
  {
    name: 'female',
    value: 'female'
  },
  {
    name: 'other',
    value: 'other'
  }
];

const COUNTRIES = [
  {
    name: 'Ghana',
    value: 'ghana'
  }
];

const PERMISSIONS = {
  viewCustomerContact: false,
  viewFinancialDetails: false,
  sendSMS: false,
  accessShop: false
};

const PERMISSIONS_OPTIONS = [
  {
    value: 'viewCustomerContact',
    label: 'View customers contact',
    permissionClaim: 1
  },
  {
    value: 'viewFinancialDetails',
    label: 'View financial details',
    permissionClaim: 2
  },
  {
    value: 'sendSMS',
    label: 'Send customers SMS',
    permissionClaim: 3
  },
  {
    value: 'accessShop',
    label: 'Access shop and inventory',
    permissionClaim: 4
  }
];

AddStaffDialog.propTypes = {
  children: PropTypes.node,
  selectedStaff: PropTypes.object,
  isStaffOpen: PropTypes.bool,
  setIsStaffOpen: PropTypes.func
};

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ----------------------------------------------------------------------

const ChildrenButtonStyle = styled(Button)(() => ({
  width: '100%',
  padding: 0
}));

// ----------------------------------------------------------------------

export default function AddStaffDialog({ children, selectedStaff, isStaffOpen, setIsStaffOpen }) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [checkPhoneQuery, setCheckPhoneQuery] = useState(null);
  const [initialValues] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address: '',
    sex: '',
    birthday: '',
    country: 'ghana',
    city: '',
    isAccountEnabled: true,
    permissions: []
  });

  const [permissions, setPermissions] = useState({
    viewCustomerContact: PERMISSIONS.viewCustomerContact,
    viewFinancialDetails: PERMISSIONS.viewFinancialDetails,
    sendSMS: PERMISSIONS.sendSMS,
    accessShop: PERMISSIONS.accessShop
  });

  // -----------------------------------------------------------------------

  const staffContainerRef = useRef(null);
  const toastRef = useRef();

  // -----------------------------------------------------------------

  const dispatch = useDispatch();
  const firestore = useFirestore();
  const { existingStaff } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const authUserUid = useSelector((state) => state.firebase.auth.uid);

  // -----------------------------------------------------------------

  const StaffSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    phoneNumber:
      selectedStaff || existingStaff?.length > 0
        ? Yup.string()
            .max(10, 'Invalid phone number')
            .min(10, 'Invalid phone number')
            .required('Phone is required')
        : Yup.string()
            .max(9, 'Invalid phone number')
            .min(9, 'Invalid phone number')
            .required('Phone is required'),
    sex: Yup.string().required('Sex is required')
  });

  const formik = useFormik({
    initialValues,
    validationSchema: StaffSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        if (selectedStaff) {
          const staffInfo = {
            ...selectedStaff,
            staffData: {
              ...values,
              searchMatch: condenseName(values.firstName, values.lastName)
            }
          };
          await dispatch(updateStaff(staffInfo));
          toastRef.current.handleOpen('Staff updated successfully');
        } else {
          const staffInfo = {
            ...values,
            phoneNumber: stringfyPhoneNumber(values.phoneNumber),
            searchMatch: condenseName(values.firstName, values.lastName)
          };
          await dispatch(addStaff(staffInfo));
          toastRef.current.handleOpen('Staff added successfully');
        }
        // reset form
        resetForm();
        setDateValue(null);
        // clsoe dialog
        handleClose();
      } catch (error) {
        staffContainerRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setError(getErrorMessage(error) || error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  // -----------------------------------------------------------------------

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError(null);
    setPermissions(PERMISSIONS);
    setOpen(false);
    if (selectedStaff) {
      setIsStaffOpen(false);
    }
  };

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
    formik.setFieldValue('birthday', newValue);
  };

  const handlePermissionChange = (event) => {
    const { name, checked } = event.target;
    setPermissions({ ...permissions, [name]: checked });
  };

  // -----------------------------------------------------------------------

  // watch isSewOpen change
  useEffect(() => {
    if (isStaffOpen) {
      setOpen(true);
      if (selectedStaff) {
        const { birthday } = selectedStaff.staffData;
        formik.setValues(selectedStaff.staffData);
        handleDateChange(typeof birthday !== 'string' ? fDate(birthday.toDate()) : birthday);
        setPermissions(
          PERMISSIONS_OPTIONS.reduce((acc, curr) => {
            acc[curr.value] = selectedStaff.staffData.permissions.includes(curr.permissionClaim);
            return acc;
          }, {})
        );
      }
    } else {
      formik.resetForm();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStaffOpen]);

  useEffect(() => {
    const selectedPermissions = PERMISSIONS_OPTIONS.filter(
      (option) => permissions[option.value]
    ).map((option) => option.permissionClaim);
    formik.setFieldValue('permissions', selectedPermissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  useEffect(() => {
    const { phoneNumber } = formik.values;
    if (phoneNumber.toString().length === 9) {
      setCheckPhoneQuery({
        collection: 'users',
        where: [['phoneNumber', '==', stringfyPhoneNumber(phoneNumber)]],
        storeAs: 'existingStaff'
      });
    }

    if (!phoneNumber.toString().length) {
      setCheckPhoneQuery(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.phoneNumber]);

  useEffect(() => {
    const checkStaffExistence = async (staffId) => {
      try {
        if (existingStaff[0].role !== 'staff') {
          const error = new Error(
            `This user is already signed up as ${existingStaff[0].role}. Please try a different number.`
          );
          error.code = 'user/different-role';
          throw error;
        }

        const docSnapshot = await firestore
          .collection('staff')
          .where('staffId', '==', staffId)
          .where('ownerId', '==', authUserUid)
          .get();

        if (!docSnapshot.empty) {
          const error = new Error('You have already added this staff');
          error.code = 'user/already-staff';
          throw error;
        }

        const { birthday } = existingStaff[0];
        formik.setValues(existingStaff[0]);
        handleDateChange(birthday ? fDate(birthday) : null);
      } catch (error) {
        staffContainerRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setError(error);
      }
    };

    if (!requesting.existingStaff && existingStaff?.length) {
      checkStaffExistence(existingStaff[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.existingStaff]);

  // -----------------------------------------------------------------------

  useFirestoreConnect(
    () =>
      checkPhoneQuery || {
        collection: 'users',
        where: [['phoneNumber', '==', null]],
        storeAs: 'existingStaff'
      }
  );

  const { errors, touched, isSubmitting, values, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <ChildrenButtonStyle onClick={handleClickOpen}>{children}</ChildrenButtonStyle>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <div ref={staffContainerRef} style={{ overflowY: 'auto' }}>
          <AppBar sx={{ position: 'sticky' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Icon icon={closeFill} width={24} height={24} />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {`${selectedStaff ? 'Edit' : 'Add'} Staff`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Container maxWidth="md" sx={{ pb: 5 }}>
            <Box sx={{ pb: 3, pt: 4 }}>
              <Typography variant="h4">{`${selectedStaff ? 'Edit' : 'Add New'} Staff`}</Typography>
            </Box>
            {error && (
              <Alert sx={{ mt: -1.5, mb: 3 }} severity="error">
                {error.message || error}
              </Alert>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={7}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          disabled={
                            !!selectedStaff ||
                            !!existingStaff?.length ||
                            error?.code === 'user/already-staff' ||
                            error?.code === 'user/different-role'
                          }
                          type="number"
                          label="Phone number"
                          {...getFieldProps('phoneNumber')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {requesting.existingStaff ? (
                                  <CircularProgress color="inherit" size={22} />
                                ) : (
                                  <>
                                    {!!existingStaff?.length && (
                                      <IconButton
                                        onClick={() => {
                                          formik.resetForm();
                                          setError(null);
                                        }}
                                        edge="end"
                                      >
                                        <Icon icon={closeFill} />
                                      </IconButton>
                                    )}
                                  </>
                                )}
                              </InputAdornment>
                            )
                          }}
                          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          disabled={
                            error?.code === 'user/already-staff' ||
                            error?.code === 'user/different-role'
                          }
                          {...getFieldProps('email')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="text"
                          label="First name"
                          {...getFieldProps('firstName')}
                          disabled={
                            !!existingStaff?.length ||
                            error?.code === 'user/already-staff' ||
                            error?.code === 'user/different-role'
                          }
                          error={Boolean(touched.firstName && errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="text"
                          label="Last name"
                          {...getFieldProps('lastName')}
                          disabled={
                            !!existingStaff?.length ||
                            error?.code === 'user/already-staff' ||
                            error?.code === 'user/different-role'
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          select
                          label="Sex"
                          {...getFieldProps('sex')}
                          disabled={
                            error?.code === 'user/already-staff' ||
                            error?.code === 'user/different-role'
                          }
                          error={Boolean(touched.sex && errors.sex)}
                          helperText={touched.sex && errors.sex}
                        >
                          {SEX.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label="Date of birth"
                            inputFormat="dd/MM/yyyy"
                            value={dateValue}
                            onChange={handleDateChange}
                            disabled={
                              error?.code === 'user/already-staff' ||
                              error?.code === 'user/different-role'
                            }
                            renderInput={(params) => <TextField fullWidth {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          select
                          label="Country"
                          disabled={
                            error?.code === 'user/already-staff' ||
                            error?.code === 'user/different-role'
                          }
                          {...getFieldProps('country')}
                        >
                          {COUNTRIES.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type="text"
                          label="City"
                          disabled={
                            error?.code === 'user/already-staff' ||
                            error?.code === 'user/different-role'
                          }
                          {...getFieldProps('city')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Address"
                          multiline
                          rows={3}
                          disabled={
                            error?.code === 'user/already-staff' ||
                            error?.code === 'user/different-role'
                          }
                          {...getFieldProps('address')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={
                                error?.code === 'user/already-staff' ||
                                error?.code === 'user/different-role'
                              }
                              {...getFieldProps('isAccountEnabled')}
                              checked={values.isAccountEnabled}
                            />
                          }
                          label="Account Enabled"
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ pt: 2 }}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        onClick={handleSubmit}
                        disabled={
                          error?.code === 'user/already-staff' ||
                          error?.code === 'user/different-role' ||
                          requesting.existingStaff
                        }
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </Form>
                </FormikProvider>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">
                      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                        Permissions
                      </Typography>
                    </FormLabel>
                    <FormGroup>
                      {PERMISSIONS_OPTIONS.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <Switch
                              disabled={
                                error?.code === 'user/already-staff' ||
                                error?.code === 'user/different-role'
                              }
                              name={option.value}
                              onChange={handlePermissionChange}
                              checked={permissions[option.value]}
                            />
                          }
                          label={option.label}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Dialog>

      <Toaster ref={toastRef} />
    </div>
  );
}
