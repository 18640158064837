import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import eyeOutlined from '@iconify/icons-ant-design/eye-outlined';
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { SewListHead, SewListToolbar, SewEmpty } from '../../components/_dashboard/owner/sewings';
import { SewViewDialog } from '../../components/_dashboard/super_admin/sewings';

import { useSewings } from '../../hooks/super_admin';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'owner', label: 'Owner', alignRight: false },
  { id: 'customer', label: 'Customer', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'sewing_status', label: 'Status', alignRight: false },
  { id: '', alignRight: true }
];

// ----------------------------------------------------------------------

function Sewings() {
  const { searchedSewings } = useSelector((state) => state.firestore.ordered);
  const { requesting } = useSelector((state) => state.firestore.status);
  const { sewingsCount } = useSelector((state) => state.firebase.profile);
  const { users } = useSelector((state) => state.firestore.data);

  // ------------------------------------------------------------------------------

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedSew, setSelectedSew] = useState(null);
  const [sewOpen, setSewOpen] = useState(false);
  // const [isSewEditOpen, setIsSewEditOpen] = useState(false);
  const [cursor, setCursor] = useState(undefined);
  const sewings = useSewings({
    startAfter: cursor,
    limit: rowsPerPage,
    filterName
  });

  // ----------------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCursor(sewings.data[sewings.data.length - 1].sewingData.createdAt);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleSewClick = (item) => {
    setSelectedSew(item);
    setSewOpen(true);
  };

  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!sewOpen) {
      setSelectedSew(null);
    }
  }, [sewOpen]);

  useEffect(() => {
    if (requesting.searchedSewings) {
      if (page) setPage(0);
      else setPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting.searchedSewings]);

  // ----------------------------------------------------------------------

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - filterName ? searchedSewings?.length : sewings.data?.length
        )
      : 0;

  return (
    <Page title="Sewings | FashionsPal">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Sewings
          </Typography>
        </Stack>

        <Card>
          {!!sewings.data.length && (
            <SewListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              requesting={requesting.searchedSewings}
            />
          )}
          {sewings.loading || requesting.searchedSewings ? (
            <Box
              sx={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress size={55} />
            </Box>
          ) : (
            <>
              {!sewings.data.length ? (
                <SewEmpty />
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <SewListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={filterName ? searchedSewings?.length || 0 : sewingsCount || 0}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {(filterName ? searchedSewings || sewings.data : sewings.data)
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const { title, customerName, status, customerData } = row.sewingData;
                            return (
                              <TableRow
                                key={index}
                                sx={{ cursor: 'pointer' }}
                                tabIndex={-1}
                                onClick={() => handleSewClick(row)}
                              >
                                <TableCell
                                  sx={{ color: `${!customerData ? 'text.disabled' : ''}` }}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {rowsPerPage * (page + 1) + (index + 1) - rowsPerPage}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: `${!customerData ? 'text.disabled' : ''}` }}
                                >
                                  {users ? users[row.ownerId]?.companyName || 'N/A' : 'N/A'}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: `${!customerData ? 'text.disabled' : ''}` }}
                                >
                                  {customerName}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: `${!customerData ? 'text.disabled' : ''}` }}
                                >
                                  {title}
                                </TableCell>
                                <TableCell align="left">
                                  <Label
                                    variant="ghost"
                                    color={
                                      (status === 'ordered' && 'error') ||
                                      ((status === 'completed' || status === 'delivered') &&
                                        'success') ||
                                      'warning'
                                    }
                                  >
                                    {sentenceCase(status)}
                                  </Label>
                                </TableCell>
                                {/* <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    startIcon={<Icon icon={eyeOutlined} />}
                                    onClick={() => handleSewClick(row)}
                                    sx={{ mr: 1.5 }}
                                  >
                                    Edit
                                  </Button>
                                </TableCell> */}
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    startIcon={<Icon icon={eyeOutlined} />}
                                    sx={{ mr: 1.5 }}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isEmpty(searchedSewings) && filterName ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </>
          )}
          {!requesting.searchedSewings && !!sewings.data.length && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filterName ? searchedSewings?.length || 0 : sewingsCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>

        {/* ----- Dialog start ----- */}
        {sewOpen && <SewViewDialog isOpen={sewOpen} setIsOpen={setSewOpen} sew={selectedSew} />}
        {/* ----- Dialog end ------ */}
      </Container>
    </Page>
  );
}

export default Sewings;
