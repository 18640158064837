// material
import { styled } from '@mui/material/styles';
import { Card, Box, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { VerificationForm } from '../components/authentication/verification';
// import AuthSocial from '../components/authentication/AuthSocial';
// layouts
import AuthLayout from '../layouts/AuthLayout';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(() => ({
  width: '100%',
  maxWidth: '45vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100vh',
  borderRadius: '0',
  position: 'fixed'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const imgStyles = {
  objectFit: 'cover',
  height: '100vh'
};

// ----------------------------------------------------------------------

export default function Verification() {
  return (
    <RootStyle title="Verification | FashionsPal">
      <AuthLayout />
      <MHidden width="mdDown">
        <div style={{ marginRight: '45vw' }}>
          <SectionStyle>
            <img alt="register" style={imgStyles} src="/static/illustrations/login.jpg" />
          </SectionStyle>
        </div>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom>
              Enter Verification Code
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Kindly enter the verification code received through SMS.
            </Typography>
          </Box>

          <VerificationForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
