import * as React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { fDate } from '../../../../utils/formatTime';

// --------------------------------------------------

CustomerViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  customer: PropTypes.object,
  setIsOpen: PropTypes.func
};

// --------------------------------------------------

export default function CustomerViewDialog({ customer, isOpen, setIsOpen }) {
  const { users } = useSelector((state) => state.firestore.data);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  // --------------------------------------------------------------------------

  React.useEffect(() => {
    if (isOpen) handleOpen();
    else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // --------------------------------------------------------------------------

  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    sex,
    country,
    city,
    address,
    birthday,
    createdAt
  } = customer.customerData;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Customer
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon icon={closeFill} width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Customer's Name
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {`${firstName} ${lastName}` || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Phone Number
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {phoneNumber || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Email
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {email || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Sex
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {sex || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Date of Birth
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {birthday ? fDate(birthday) : 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Country
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {country || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  City
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {city || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Address
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {address || 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Created on
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {createdAt ? fDate(createdAt.toDate()) : 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Owner
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {users ? users[customer.ownerId]?.companyName || 'N/A' : 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Owner's Name
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {users
                    ? `${users[customer.ownerId]?.firstName} ${users[customer.ownerId]?.lastName}`
                    : 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" component="p">
                  Owner's Contact
                </Typography>
                <Typography variant="body2" component="p" style={{ marginBottom: '20px' }}>
                  {users ? users[customer.ownerId]?.phoneNumber || 'N/A' : 'N/A'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
