import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fPhoneNumberToEmail } from '../../../utils/formatPhoneNumber';
import { firebaseConfig } from '../../../config/firebase';

// _mock_ data
// import CUSTOMERS from '../../_mocks_/customers';

export const addCustomer = createAsyncThunk(
  'customer/add',
  async ({customerInfo, ownerId}, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { phoneNumber } = customerInfo;

    const formattedPhoneNumberToEmail = fPhoneNumberToEmail(phoneNumber);
    const defaultPassword = '123456';

    const timestamp = firestore.FieldValue.serverTimestamp();
    const rest = {
      role: 'customer',
      createdAt: timestamp,
      updatedAt: timestamp
    };

    let customerAuthUid;
    let checkIfUserExists;

    if (phoneNumber) {
      checkIfUserExists = await firebase
        .auth()
        .fetchSignInMethodsForEmail(formattedPhoneNumberToEmail);
    }

    if (phoneNumber) {
      if (!(checkIfUserExists.length > 0)) {
        const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');
        const customerAuth = await secondaryApp
          .auth()
          .createUserWithEmailAndPassword(formattedPhoneNumberToEmail, defaultPassword);
        customerAuthUid = customerAuth.user.uid;

        await secondaryApp.auth().signOut();

        await firestore.set(
          { collection: 'users', doc: customerAuthUid },
          { ...customerInfo, ...rest }
        );
      } else {
        const userDocRef = await firestore
          .collection('users')
          .where('phoneNumber', '==', phoneNumber)
          .get();
        customerAuthUid = userDocRef.docs[0].id;
      }
    } else {
      customerAuthUid = firestore.collection('customers').doc().id;
    }

    await firestore.set(
      { collection: 'customers', doc: `${ownerId}_${customerAuthUid}` },
      {
        ownerId,
        customerId: customerAuthUid,
        customerData: {
          ...customerInfo,
          ...rest
        }
      }
    );
  }
);

export const updateCustomer = createAsyncThunk(
  'customer/update',
  async (customer, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const { ownerId, customerId } = customer;

    await firestore.update(
      { collection: 'customers', doc: `${ownerId}_${customerId}` },
      {
        customerData: {
          ...customer.customerData,
          updatedAt: firestore.FieldValue.serverTimestamp()
        }
      }
    );
  }
);

export const removeCustomer = createAsyncThunk(
  'customer/delete',
  async (customerData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();
    const { ownerId, customerId } = customerData;

    firestore.delete({
      collection: 'customers',
      doc: `${ownerId}_${customerId}`
    });
  }
);

const initialState = {
  error: null
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addCustomer.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // -----------------------------------------------------------------------

    builder.addCase(updateCustomer.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });

    // ------------------------------------------------------------------------

    builder.addCase(removeCustomer.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
      throw action.error;
    });
  }
});

export default customersSlice.reducer;
