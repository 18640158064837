import { useState } from 'react';
import { capitalCase } from 'change-case';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
// material
import { Typography, Button, Grid, Card, Stack, Box, Tabs, Tab, Container } from '@mui/material';
//
import Page from '../../components/Page';
import {
  SubscriptionsCurrentBundle,
  SubscriptionsPlans,
  SubscriptionsTopups
} from '../../components/_dashboard/owner/subscriptions';

import useScript from '../../hooks/useScript';

const SUBSCRIPTION_TABS = [
  {
    value: 'plans',
    component: <SubscriptionsPlans />
  },
  {
    value: 'top ups',
    component: <SubscriptionsTopups />
  }
];

export default function Subscriptions() {
  const { uid } = useSelector((state) => state.firebase.auth);
  const { subscriptionsActive } = useSelector((state) => state.firestore.ordered);
  const { subscriptionsPlans } = useSelector((state) => state.firestore.data);
  const plan =
    subscriptionsActive && subscriptionsPlans
      ? {
          planName: subscriptionsPlans[subscriptionsActive[0]?.plan]?.name,
          planBundle: subscriptionsActive[0]?.bundle
        }
      : null;

  const [currentTab, setCurrentTab] = useState('plans');

  const queriesArr = [
    {
      collection: 'subscriptionsActive',
      where: [['ownerId', '==', uid]]
    }
  ];

  // --------------------------------------------------------------------------

  useFirestoreConnect(queriesArr);
  useScript('https://js.paystack.co/v1/inline.js');

  return (
    <Page title="Subscriptions | FashionsPal">
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Stack spacing={3} sx={{ mb: 4 }}>
              <Card sx={{ p: 3 }}>
                <Typography
                  variant="overline"
                  sx={{ mb: 3, display: 'block', color: 'text.secondary' }}
                >
                  Your Plan
                </Typography>
                <Typography variant="h4">{plan?.planName || ''}</Typography>
                <Box
                  sx={{
                    mt: { xs: 2, sm: 0 },
                    position: { sm: 'absolute' },
                    top: { sm: 24 },
                    right: { sm: 24 }
                  }}
                >
                  <Button
                    size="small"
                    color="inherit"
                    variant="outlined"
                    sx={{ mr: 1 }}
                    onClick={() => setCurrentTab('top ups')}
                  >
                    Top Up
                  </Button>
                  <Button size="small" variant="outlined" onClick={() => setCurrentTab('plans')}>
                    Renew/Upgrade
                  </Button>
                </Box>
              </Card>
            </Stack>

            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={(e, value) => setCurrentTab(value)}
            >
              {SUBSCRIPTION_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={capitalCase(tab.value)}
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>

            <Box sx={{ mb: 1 }} />

            {SUBSCRIPTION_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
          </Grid>

          <Grid item xs={12} md={4}>
            <SubscriptionsCurrentBundle bundle={plan?.planBundle || []} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
