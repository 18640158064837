import * as React from 'react';
import Proptypes from 'prop-types';
import { useDispatch } from 'react-redux';
// material
import {
  Box,
  // Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { changeSewingStatus } from '../../../../redux/slices/owner/sewings';

SewStatusDialog.propTypes = {
  isOpen: Proptypes.bool,
  setIsOpen: Proptypes.func,
  selectedSew: Proptypes.object
};

export default function SewStatusDialog({ isOpen, setIsOpen, selectedSew }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  // ----------------------------------------------------------------------

  const dispatch = useDispatch();

  // ----------------------------------------------------------------------

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setIsOpen(false);
  }, [setIsOpen]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSave = async () => {
    if (selectedSew) {
      try {
        const sewingInfo = {
          ...selectedSew,
          sewingData: {
            ...selectedSew.sewingData,
            status: value
          }
        };
        setIsLoading(true);
        await dispatch(changeSewingStatus(sewingInfo));
        handleClose();
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // ----------------------------------------------------------------------

  React.useEffect(() => {
    if (isOpen) {
      handleOpen();
      if (selectedSew) {
        setValue(selectedSew.sewingData.status);
      }
    } else handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen]);

  // ----------------------------------------------------------------------

  return (
    <>
      <Dialog maxWidth="xs" open={open} onClose={handleClose}>
        {error && (
          <Alert sx={{ maxWidth: '230px' }} severity="error">
            {error}
          </Alert>
        )}
        <DialogTitle sx={{ textAlign: 'center' }}>Sew Status</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content'
            }}
          >
            <FormControl sx={{ mt: 1, minWidth: 160 }}>
              <RadioGroup
                name="sewing-status"
                value={value}
                onChange={handleChange}
                sx={{ ml: 'auto', mr: 'auto' }}
              >
                <FormControlLabel
                  value="ordered"
                  control={<Radio color="error" />}
                  label="Ordered"
                />
                <FormControlLabel
                  value="cutting"
                  control={<Radio color="warning" />}
                  label="Cutting"
                />
                <FormControlLabel
                  value="sewing"
                  control={<Radio color="warning" />}
                  label="Sewing"
                />
                <FormControlLabel
                  value="completed"
                  control={<Radio color="success" />}
                  label="Completed"
                />
                <FormControlLabel
                  value="delivered"
                  control={<Radio color="success" />}
                  label="Delivered"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} onClick={handleSave}>
            Save
          </LoadingButton>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
