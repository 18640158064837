import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { imagesUrlArrayGet } from '../../../utils/imagesUrlGenerator';

export const addShop = createAsyncThunk(
  'shops/add',
  async (shopData, { extra: { getFirestore, getFirebase} }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const uid = firebase.auth().currentUser.uid;
    const newShopsRefId = firestore.collection('shops').doc().id;
    const timestamp = firestore.FieldValue.serverTimestamp();
    firestore.set(
      { collection: 'shops', doc: newShopsRefId },
      {
        ...shopData,
        ownerId: uid,
        createdAt: timestamp,
        updatedAt: timestamp,
      }
    );
  }
);

export const changeShopStatus = createAsyncThunk(
  'shops/changeStatus',
  async (shopData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();

    const shop = await firestore.update({ collection: 'shops', doc: shopData.id }, shopData);

    return shop;
  }
);

export const updateShop = createAsyncThunk(
  'shops/update',
  async (shopData, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();
    // const firebase = getFirebase();
    const { id } = shopData;
    // const { fabrics, styles } = shopData.shopData;
    const timestamp = firestore.FieldValue.serverTimestamp();

    // if (fabrics && fabrics.length) {
    //   shopData.fabrics = await imagesUrlArrayGet(firebase, id, fabrics);
    // }

    // if (styles && styles.length) {
    //   shopData.styles = await imagesUrlArrayGet(firebase, id, styles);
    // }

    const shop = await firestore.update(
      { collection: 'shops', doc: id },
      {
        ...shopData,
        shopData: {
          ...shopData.shopData,
          updatedAt: timestamp
        }
      }
    );
    return shop;
  }
);

export const removeShop = createAsyncThunk(
  'shops/remove',
  async (shopData, { extra: { getFirestore } }) => {
    const firestore = getFirestore();
    firestore.delete({ collection: 'shops', doc: shopData.id });
  }
);

const initialState = {
  error: null
};

const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addShop.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(addShop.rejected, (state, action) => {
      state.error = action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(changeShopStatus.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(changeShopStatus.rejected, (state, action) => {
      state.error = action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(updateShop.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(updateShop.rejected, (state, action) => {
      state.error = action.error;
    });

    // ----------------------------------------------------------------

    builder.addCase(removeShop.fulfilled, (state) => {
      state.error = null;
    });

    builder.addCase(removeShop.rejected, (state, action) => {
      state.error = action.error;
    });
  }
});

export default shopsSlice.reducer;
